<template>
  <FriendlierCard>
    <LocaleSwitcher />
    <div class="padded-block">
      <div class="logo"><img :src="FriendlierPrimaryOcean" /></div>
      <Loading v-if="loading" isCenter />
      <span v-if="!loading">
        <RegisterForm
          :locked="locked"
          showEmail
          title="Join Friendlier"
          :buttonText="$t('loginVue.sign-up')"
          :submit="submit"
        />
        <div class="mt-4 has-text-danger error">{{ error }}</div>
        <hr class="is-back-cloud" />
        <p class="has-text-weight-light mt-2 is-cloud">
          {{ $t("signup.account") }}
        </p>
        <span class="ocean-button is-bold fs-1-2" :onclick="login">{{ $t("signup.login") }}</span>
      </span>
    </div>
  </FriendlierCard>
</template>
<script setup>
import { ref } from "vue";
import { FriendlierPrimaryOcean } from "@/revamp/assets/images";
import { useFirebase } from "@/revamp/composables";
import { useRouter } from "vue-router";
import { logError } from "@/revamp/utils";
import { Loading, RegisterForm, FriendlierCard } from "@/revamp/components";
import LocaleSwitcher from "@/components/LocaleSwitcher";

const props = defineProps({
  login: Function,
});

const error = ref("");
const loading = ref(false);
const locked = ref(false);

const APIService = useFirebase();
const Router = useRouter();

async function submit({ fname, lname, password, userEmail }) {
  locked.value = true;
  userEmail = userEmail.toLowerCase().trim();
  try {
    let signUpResponse = await APIService.signup(userEmail, password, fname, lname);

    if (signUpResponse == null) {
      throw new Error("Signup error");
    }

    let sendVerificationResponse = await APIService.sendVerificationEmail(userEmail);
    if (sendVerificationResponse == null) {
      throw new Error("Server Error");
    }

    if (sendVerificationResponse.error) {
      if (sendVerificationResponse.code != 20000) {
        throw new Error("Server Error");
      }
    }

    Router.push("Dashboard");
  } catch (e) {
    logError(`Signup.submit: Error during ${userEmail} signup. ${e.toString()}`);
    error.value = "Something went wrong, please try again in a few minutes or reach out to Friendlier for help.";
  }
  locked.value = false;
  loading.value = false;
}
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.logo {
  max-width: 30%;
  min-width: 300px;
  margin: 0 auto;
}
</style>
