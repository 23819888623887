<template>
  <div
    :class="{
      full: isFull,
      isCenter: isCenter,
      contained: isContained,
      cover: isCover,
      small: isSmall,
    }"
  >
    <img :src="LoadingWheel" class="loading-img" />
  </div>
</template>
<script>
import { LoadingWheel } from "@/revamp/assets/images";
export default {
  props: {
    isFull: Boolean,
    isCenter: Boolean,
    isContained: Boolean,
    isCover: Boolean,
    isSmall: Boolean,
  },
  setup() {
    return {
      // Images
      LoadingWheel,
    };
  },
};
</script>
<style lang="scss">
@import "@/revamp/assets/scss/colors.scss";

.full {
  height: 100%;
  width: 100%;
}
.isCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-img {
  height: 100%;
  width: 100%;
  max-height: 200px;
  max-width: 200px;
}
.contained {
  height: 100%;
}

.small {
  max-height: 50px;
  max-width: 50px;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $cloud;
  z-index: 5000;
}
</style>
