import logError from "./errorLogger";
import { AuthService as auth } from "@/revamp/firebase/config";

async function request(url, method, body, context, withUserToken) {
  let headers = new Headers();
  try {
    headers.append('x-api-key', process.env.VUE_APP_FRIENDLIER_API_KEY)
    if (withUserToken) {
      headers.append('x-user-token', await auth.currentUser.getIdToken(true));
    }
  } catch (err) {
    logError(`Request.${context}: Failed to setup headers. ${err.toString()}`);
  }
  
  try {
    let options = {
      method: method,
      headers: headers,
    }

    if (body){
      options['body'] = JSON.stringify(body);
    }

    let response = await fetch(url, options);
    let responseBody = await response.json();

    if (response.status == 400)
    throw new Error("Bad Request")
    if (response.status == 401)
    throw new Error("Unauthorized")
    if (response.status == 403)
      throw new Error("Access Denied")
    if (response.status == 404)
      throw new Error("Endpoint not found")
    if (response.status == 500) {
      throw new Error(
        `Server Error ${response.status} - ${responseBody["message"]}`
      );
    }
    if (responseBody['error']) {
      throw new Error(responseBody['message'])
    }
    return responseBody

  } catch (err) {
    logError(`Request.${context}: Failed to request. ${err.toString()}`);
    throw new Error(`Failed to request. ${context}. ${err.toString()}`);
  }
}

export default request;