import { AuthService, DatabaseService, timestamp, FIREBASE_FUNCTION_HEADERS, REQUEST, adjustVal } from "@/revamp/firebase/config";
import { logError } from "@/revamp/utils";

async function login(email, password) {
  try {
    let response = await AuthService.signInWithEmailAndPassword(email, password);
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

async function logout() {
  try {
    let response = await AuthService.logout();
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

async function signup(email, password, fname, lname) {
  try {
    let createAuthUserResponse = await AuthService.createUserWithEmailAndPassword(email, password);
    if (!createAuthUserResponse || !createAuthUserResponse.user) {
      logError("UserFunctions.Signup: Signup Failed");
      throw new Error("Signup failed");
    }
    let updateProfileResponse = await createAuthUserResponse.user.updateProfile({ displayName: `${fname} ${lname}` });

    let createBaseUserResponse = await setUserBaseData(createAuthUserResponse.user.uid, fname, lname, email);

    if (createBaseUserResponse == false) {
      throw new Error("Failed to create user base data");
    }

    return { createAuthUserResponse, updateProfileResponse, createBaseUserResponse };
  } catch (e) {
    logError(`UserFunctions.Signup: ${e.toString()}`);
    return null;
  }
}

async function setUserBaseData(uid, fname, lname, email) {
  try {
    let data = {
      uid: uid,
      balance: 0,
      cashed: 0,
      email: email,
      "etransfer email": email,
      fcmtoken: "",
      fname: fname,
      lastNotif: timestamp(),
      lname: lname,
      pendingBalance: 0,
      totalConfirmed: 0,
      totalUnconfirmed: 0,
      totalUserCount: 0,
      tutorialDone: false,
    };

    let response = await DatabaseService.collection("users").doc(uid).set(data, { merge: true });

    return response;
  } catch (e) {
    logError(`UserFunctions.SetUserBaseData: ${e.toString()}`);
    return false;
  }
}

async function checkVerificationLink(verifyLink) {
  if (verifyLink == null || verifyLink == "" || verifyLink == undefined) {
    return null;
  }
  try {
    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/CheckVerificationLink`, {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ verifyLink: verifyLink }),
    });

    return await response.json();
  } catch (e) {
    logError(`UserFunctions.CheckVerificationLink: Failed to check verification link. ${e.toString()}`);
    return null;
  }
}

async function sendVerificationEmail(email) {
  try {
    let response = await fetch(process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + "/SendEmailVerification", {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ email: email }),
    });
    return await response.json();
  } catch (e) {
    logError(`UserFunctions.SendVerificationEmail: ${e.toString()}`);
    return null;
  }
}

async function verifyUser(email) {
  try {
    let response = await fetch(process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + "/VerifyUser", {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ id: email }),
    });
    let data = await response.json();
    if (data['status'] == 'success') return true; else return false;
  } catch (e) {
    logError(`UserFunctions.VerifyUser: ${e.toString()}`);
    return false;
  }
}

async function checkSignUpLink(signUpLink) {
  if (signUpLink == null || signUpLink == "" || signUpLink == undefined) {
    return null;
  }
  try {
    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/CheckSignUpLink`, {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ signUpLink: signUpLink }),
    });

    return await response.json();
  } catch (e) {
    logError(`UserFunctions.CheckSignUpLink: Failed to check sign up link. ${e.toString()}`);
    return null;
  }
}
async function validateLink(link) {
  try {
    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/linkValidation`, {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ 
        link: link,
        type: "emailUpdateLink"
      }),
    });

    return await response.json();
  } catch (e) {
    logError(`UserFunctions.ValidateLink: Failed to validate link. ${e.toString()}`);
    return null;
  }

}

async function sendSignupEmail(email) {
  return await REQUEST("/SendSignupEmail", { email: email }, "UserFunctions.SendSignupEmail");
}

async function finishSignup(email, password, fname, lname, willVerify) {
  if (willVerify == null || willVerify == undefined) willVerify = false;
  return await REQUEST("/CompleteSignup", { email, password, fname, lname, willVerify }, "UserFunctions.FinishSignup");
}

async function forgotPassword(email) {
  try {
    let response = await AuthService.sendResetEmail(email);
  } catch (e) {
    throw new Error(e);
  }
}

async function getUserDetails(uid) {
  try {
    let userRef = DatabaseService.collection("users").doc(uid);
    let userDoc = await userRef.get();
    if (!userDoc.exists) throw new Error("User Not Found")

    let data = userDoc.data();
    if (data == null || undefined) throw new Error("User object empty");
    
    return data;
  } catch (err) { 
    logError(`UserFunctions.getUserDetails: Failed to fetch user, ${uid}. ${err.toString}`);
    return undefined
  }
}

async function addUserTransaction(uid,data) {
  try {
    let doc = await DatabaseService.collection("users").doc(uid).collection("transactions").add(data);
    return doc.id;
  } catch (err) {
    logError(f`UserFunctions.addUserTransaction: Failed to add transaction. ${err.toString()}`)
  }
}

async function addUserDonation(uid,charityID,donation) {
  try {
    await DatabaseService.collection('users').doc(uid).collection("donations").doc(charityID).set({ val: adjustVal(donation)}, {merge: true});
    await DatabaseService.collection('users').doc(uid).collection("donations").doc('total').set({ val: adjustVal(donation)},{merge: true});
  } catch (err) {
    logError(`userFunctions.addUserDonation: Failed to add user donation. ${uid}. ${charityID}. ${donation}. ${err.toString()}`)
  }
}

async function updateUser(uid, data) {
  try {
    await DatabaseService.collection("users").doc(uid).set(data, {merge: true})
  } catch (err) {
    logError(`userFunctions.updateUser: Failed to update user. ${uid}. ${JSON.stringify(data)}. ${err.toString()}`)
  }
}

const users_functions = {
  login,
  logout,
  signup,
  finishSignup,
  sendVerificationEmail,
  checkVerificationLink,
  checkSignUpLink,
  validateLink,
  sendSignupEmail,
  verifyUser,
  getUserDetails,
  addUserTransaction,
  addUserDonation,
  updateUser,
};

export default users_functions;
