<template>
  <div class="content">
    <Background>
      <template v-if="success">
        <div class="message success">Container Successfully Scanned</div>
      </template>
      <template v-if="failed">
        <div class="message failure">Container Failed to Scanned</div>
      </template>
      <Loading v-if="loading" isCenter isFull />
      <FriendlierCard v-else>
        <div class="padded-block">
          <div class="logo"><img :src="FriendlierPrimaryCloud" /></div>
          <OutletClaim @submit="claim" :container="container" />
          <div class="is-cloud mt-4">
            <Loading v-if="claimLoading" isCenter isSmall />
          </div>
        </div>
        <div
          class="image-spacer"
          :style="{ backgroundImage: 'url(' + FourContainerSushiFirst + ')' }"
        />
      </FriendlierCard>
    </Background>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { FriendlierPrimaryCloud } from "@/revamp/assets/images";
import { FourContainerSushiFirst } from "@/revamp/assets/images";

import {
  OutletClaim,
  Loading,
  Background,
  FriendlierCard,
} from "@/revamp/components";

import { logError } from "@/revamp/utils";
import { useFirebase } from "@/revamp/composables";

const route = useRoute();
const APIService = useFirebase();

const store = ref("");
const banner = ref("");

// old

const container = ref("");

const loading = ref(true);
const claimLoading = ref(false);
const success = ref(false);
const failed = ref(false);

watch(success, () => {
  if (success.value) {
    setTimeout(() => {
      success.value = false;
    }, 4000);
  }
});

watch(failed, () => {
  if (failed.value) {
    setTimeout(() => {
      failed.value = false;
    }, 4000);
  }
});

onMounted(async () => {
  try {
    let path = route.path.split("/");
    let offset = 1;
    if (path[path.length - offset] == "") offset++;

    store.value = path[path.length - offset++].toUpperCase();
    banner.value = path[path.length - offset].toLowerCase();

    loading.value = false;
  } catch (err) {
    logError(`outletScan.onMounted: ${err.toString()}`);
    window.location.href = "/";
  }
});

async function claim(containerID) {
  claimLoading.value = true;
  try {
    await APIService.outletQuickClaim(store.value, containerID);
    success.value = true;
  } catch (err) {
    failed.value = true;
    logError(`outletScan.claim: ${err.toString()}`);
  }
  claimLoading.value = false;
}
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";
@import "@/revamp/assets/scss/variables.scss";

.logo {
  max-width: 30%;
  min-width: 100%;
}

.image-spacer {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 0px 25px 25px;
}

.message {
  position: fixed;
  top: 5px;
  left: 10%;
  width: 80%;
  height: fit-content;
  background-color: $denim;
  padding: 20px;
  border-bottom: 1px solid $cloud;
  box-shadow: 0px 0px 10px 0px $cloud;
  text-align: center;
  border-radius: 15px;
}

.success {
  color: $green-highlight;
}

.failure {
  color: $red-highlight;
}
</style>
