<template>
  <CustomerNavbar />

  <Loading v-if="loading" isFull isCenter />

  <template v-else>
    <div class="background-image">
      <!-- Hero section(Heythere section) start-->
      <div class="is-hcentered mt-5">
        <div class="hero is-halfheight" id="background_hero">
          <div class="hero-body">
            <div class="container">
              <div class="columns">
                <div class="column pt-6 mt-6">
                  <div class="box is-shadowless p-0 is-back-denim-translucent">
                    <p class="is-size-5 has-text-light">
                      {{ $t("displayVue.hey", { firstname }) }}
                    </p>
                    <p class="is-size-3 has-text-light is-family-secondary">
                      {{ $t("displayVue.welcome-back", { firstname }) }}
                    </p>
                  </div>
                </div>
                <div class="column pt-6 mt-6">
                  <div class="columns">
                    <div class="column">
                      <div class="box is-shadowless p-0 is-back-denim-translucent">
                        <p class="is-size-3 has-text-light is-family-secondary has-text-centered">
                          {{ reused ? reused : 0 }}
                        </p>
                        <p class="is-size-5 has-text-light has-text-centered">
                          {{ $t("displayVue.containers-reused") }}
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="box is-shadowless p-0 is-back-denim-translucent">
                        <p class="is-size-3 has-text-light is-family-secondary has-text-centered">
                          ${{ balance ? balance : 0 }}
                        </p>
                        <p class="is-size-5 has-text-light has-text-centered">
                          {{ $t("displayVue.available-balance") }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div class="column">
                        <div class="box is-shadowless p-0 is-back-denim-translucent">
                          <p class="is-size-3 has-text-light is-family-secondary has-text-centered">
                            ${{ pendingBalance ? pendingBalance : 0 }}
                          </p>
                          <p class="is-size-5 has-text-light has-text-centered">
                            {{ $t("displayVue.pending-balance") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Hero section ends -->

      <!-- User info -->
      <div class="container is-fluid p-6 is-back-cloud">
        <div class="columns">
          <div class="column is-three-quarters mt-5">
            <Equivalencies :reused="reused" />
            <hr class="is-back-denim with-space" />
            <p class="is-size-5 has-text-centered is-family-secondary ml-3">
              {{ $t("displayVue.pending-return", { totalUnconfirmed }) }}
            </p>
            <div class="mt-3 has-text-centered">
              <a
                class="button has-text-centered is-primary"
                href="https://www.friendlier.ca/consumers#comp-laju6icx"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t("displayVue.find-dropoff") }}
              </a>
            </div>
          </div>

          <!-- Claim Container part -->
          <div class="column">
            <ClaimContainer @claim="init" :verify="toggleModal" :sendVerification="requestVerification" />
          </div>
        </div>
      </div>

      <!-- Deposit Payment heading only -->
      <div class="container is-back-transparent p-6">
        <div class="is-back-denim-translucent">
          <p class="is-size-3 has-text-centered is-family-secondary is-sunflower">
            {{ $t("displayVue.deposit-payment") }}
          </p>
          <p class="has-text-centered is-size-5 mb-4 is-sunflower">
            {{ $t("displayVue.local-charity") }}
          </p>
        </div>
      </div>

      <div class="container is-fluid is-back-cloud mb-6">
        <PaymentRequest :verify="toggleModal" :sendVerification="requestVerification" :update="init" />
        <hr class="with-space is-back-denim" />
        <DonateRequest :createModal="toggleModal" :sendVerification="requestVerification" @balance="init" />
      </div>

      <div v-if="showModal" class="notice-modal">
        <div class="modal-background" @click="() => toggleModal({})"></div>
        <div class="modal-container">
          <div class="modal-header">
            {{ modalDetails.title }}
            <button class="delete" aria-label="close" @click="() => toggleModal({})" v-on:click="showModal = false"></button>
          </div>
          <div class="modal-body">
            {{ modalDetails.body }}

            <div class="modal-action-row">
              <div class="modal-action" v-for="button in modalDetails.buttons" :key="button.text" @click="button.action">
                {{ button.text }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </template>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import { getUser } from "@/composables";
import { useFirebase } from "@/revamp/composables";
import { CustomerNavbar, DonateRequest, PaymentRequest, ClaimContainer, Equivalencies, Footer } from "@/components";

import { logError } from "@/revamp/utils";
import { Loading } from "@/revamp/components";

import { useI18n } from "vue-i18n";

const { user } = getUser();
const _fb = useFirebase();
const loading = ref(true);

const showModal = ref(false);
const modalDetails = ref({});

const firstname = ref("");
let balance = ref(0);
let reused = ref(0);
let pendingBalance = ref(0);
let totalUnconfirmed = ref(0);

const { t } = useI18n({
  locale: "en",
  messages: {
    en: {
      success: "Success",
      dismiss: "Dismiss",
      failure: "Failure",
      error:
        "Something went wrong while trying to send your email. Please reach out to Friendlier for support or try again later.",
      successful: "Email sent ! Check your inbox, make sure to check your junk too :)",
    },
    fr: {
      success: "Succès",
      dismiss: "Rejeter",
      failure: "Échec",
      error:
        "Un problème est survenu lors de l'envoi de votre courriel. Veuillez contacter Friendlier pour obtenir de l'aide ou réessayer plus tard.",
      successful: "Courriel envoyé ! Vérifiez votre boîte de réception, assurez-vous de vérifier vos indésirables aussi :)",
    },
  },
});
const msg = computed(() => t("error", "success", "dismiss", "failure", "successful"));

//Function to get data on the current logged in user
const init = async () => {
  const userData = await _fb.getUserDetails(user.value.uid);
  balance.value = userData.balance;
  reused.value = userData.totalUserCount;
  pendingBalance.value = userData.pendingBalance;
  firstname.value = userData.fname;
  totalUnconfirmed.value = userData.totalUnconfirmed;

  loading.value = false;
};

onMounted(async () => {
  init();
});

//Function to request email verification for new users
async function requestVerification() {
  try {
    let api = useFirebase();
    await api.sendVerificationEmail(user.value.email);
  } catch (err) {
    logError(`Display.RequestVerification: Failed to send verification email. ${err.toString()}`);
    toggleModal({
      title: t("failure"),
      body: t("error"),
      buttons: [
        {
          text: t("dismiss"),
          action: () => toggleModal({}),
        },
      ],
    });
    return;
  }
  toggleModal({
    title: t("success"),
    body: t("successful"),
    buttons: [
      {
        text: t("dismiss"),
        action: () => toggleModal({}),
      },
    ],
  });
}

async function toggleModal(details) {
  showModal.value = !showModal.value;
  modalDetails.value = details;
}
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";
.background-image {
  background-image: url("../assets/patterns/friendlier-pattern-05.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.hero {
  width: 100%;
}

#pickup_button {
  padding-left: 50px;
  padding-right: 50px;
}
#image1 {
  height: 85%;
  margin: auto;
  display: block;
}

.notice-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 31;

  .modal-container {
    background-color: transparent;
    z-index: 100;
    position: relative;

    .modal-header {
      max-height: 70px;
      background-color: darken($cloud, 15);
      min-height: 30px;
      height: 100%;
      width: 30vw;

      display: flex;
      justify-content: space-between;

      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 15px;
    }

    .modal-body {
      max-height: 300px;
      min-height: 100px;
      width: 30vw;

      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      background-color: $cloud;
      padding: 15px;

      .modal-action-row {
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding-top: 15px;

        .modal-action {
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-color: $denim;
          color: $cloud;
          border-radius: 15px;
          padding: 7px 12px;
        }
      }
    }
  }

  .modal-background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 35;
    background-color: rgba(darken($cloud, 50), 0.7);
  }
}

.dropdown {
  outline: lightblue;
  background-color: red;
  appearance: none;
  color: #4a4a4a;
  font-size: 1.25rem !important;
  // padding: 0.5rem 0.75rem;
  padding-top: 0.5rem;
  // padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}
</style>
