export default {
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
  "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
  "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poursuivre"])},
  "loginVue": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "log-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courriel"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mot de passe"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte?"])},
    "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous"])},
    "err-enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un courriel et un mot de passe"])},
    "err-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe ou courriel invalide"])}
  },
  "displayVue": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accueil"])},
    "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mon compte"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emplacements"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["communiquez avec nous"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changer le mot de passe"])},
    "change-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changer le courriel"])},
    "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déconnexion"])},
    "hey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "welcome-back": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["bienvenue, ", _interpolate(_named("firstname")), "!"])},
    "containers-reused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenants réutilisés"])},
    "available-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solde disponible"])},
    "pending-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solde en suspens"])},
    "your-containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vos contenants"])},
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir choisi de réutiliser ! Vous faites une grande différence."])},
    "impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre impact seul équivaut à :"])},
    "plastic-waste": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reused")), "g de déchets en plastique"])},
    "charge-phone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'énergie pour charger votre téléphone ", _interpolate(_named("reused")), " fois"])},
    "co2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reused")), "g d'émission de CO2"])},
    "pending-return": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("totalUnconfirmed")), " contenants en attente de retour"])},
    "find-dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trouvez un emplacement pour le retour"])},
    "deposit-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dépôts"])},
    "local-charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez le transfert électronique Interac de vos dépôts, ou choisissez d'en faire don à une organisation caritative locale."])},
    "err-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors de l'envoi de votre courriel. Veuillez contacter Friendlier pour obtenir de l'aide ou réessayer plus tard."])},
    "success-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel envoyé ! Vérifiez votre boîte de réception, assurez-vous de vérifier vos indésirables aussi :)"])}
  },
  "paymentRequest": {
    "request-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demandez un paiement"])},
    "confirm-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre courriel de transfert électronique ci-dessous pour demander le paiement."])},
    "confirm-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez la demande"])},
    "change-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez le courriel de transfert"])},
    "enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre courriel de transfert électronique ci-dessous."])},
    "etransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de transfert électronique"])},
    "confirm-etransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre courriel de transfert électronique"])},
    "sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("currBalance")), " sera envoyé à ", _interpolate(_named("eTransferEmail")), ". Souhaitez-vous poursuivre ?"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux poursuivre"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel de virement bancaire a été mis à jour avec succès !"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la mise à jour du courriel de virement bancaire ; veuillez réessayer ou contacter Friendlier pour obtenir de l'aide"])},
    "make-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous assurer que le nouveau courriel de virement bancaire est différent de votre courriel actuel"])},
    "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les courriels ne correspondent pas"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel avant de demander le paiement d'une consigne."])},
    "not-enough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas assez d'argent dans votre solde pour demander un paiement"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de paiement envoyée ! Le virement bancaire sera effectué dans les 3 à 5 jours ouvrables"])},
    "failed-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La demande de paiement a échoué, réessayez plus tard ou contactez Friendlier pour obtenir de l'aide"])},
    "current-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email actuel"])}
  },
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel"])},
  "changePassword": {
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changez votre mot de passe"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité, vous devrez vérifier votre boîte de courriel pour changer de mot de passe."])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour que le mot de passe soit envoyé à votre courriel. Vous serez déconnecté après avoir cliqué"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte de réception ! Nous vous avons envoyé un courriel contenant un lien pour changer votre mot de passe."])}
  },
  "changeEmailRequest": {
    "change-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changer le courriel"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité, vous devrez vérifier votre boîte de courriel pour changer de courriel."])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour que le courriel soit envoyé à votre courriel. Vous serez déconnecté après avoir cliqué"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte de réception ! Nous vous avons envoyé un courriel contenant un lien pour changer votre courriel."])}
  },
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le courriel de vérification"])},
  "claimContainer": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réclamez vos consignes!"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible sur IOS et Android"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez notre application mobile pour réclamer votre consigne."])},
    "already-claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contenant a déjà été réclamé veuillez le placer dans un bac de collecte."])},
    "oops-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, un problème est survenu. Veuillez réessayer"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel"])},
    "verify-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel avant de réclamer un contenant."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contenant a été réclamé avec succès ! Veuillez le placer dans un bac de collecte."])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu, veuillez réessayer plus tard"])}
  },
  "donateRequest": {
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faites don solde"])},
    "donate-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites don de votre solde à l'une des organisations de bienfaisance que nous recommandons"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "donate-to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faire don de mes consignes à ", _interpolate(_named("name"))])},
    "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporairement en panne"])},
    "service-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que notre service de donation est actuellement en panne - nous travaillons à réparer cela rapidement ! Nous vous remercions."])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$ seront versés à cette œuvre de bienfaisance. Souhaitez-vous poursuivre ?"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel avant de faire don de votre consigne."])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "registerForm": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prénom"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de famille"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courriel"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le mot de passe"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmez le mot de passe"])},
    "valid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un courriel valide"])},
    "all-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
    "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglez les problèmes avant de soumettre"])}
  },
  "signup": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez réessayer dans quelques minutes ou demander de l'aide à Friendlier."])}
  },
  "validator": {
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter plus de 8 caractères"])},
    "password-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])}
  },
  "forgotPassword": {
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mote de passe?"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre courriel et nous vous enverrons un courriel pour réinitialiser votre mot de passe:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un courriel"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel pour réinitialiser votre mot de passe"])}
  },
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
}