<template>
  <div class="claim-container">
    <div class="title">Claim your deposit:</div>
    <div class="input-container">
      <div class="label">Code:</div>
      <input
        class="input"
        :v-model="containerID"
        type="text"
        :value="containerID"
        @input="containerChange"
        placeholder="Enter the code on your container"
      />
    </div>
    <div class="input-container">
      <div class="label">Email:</div>
      <input
        class="input"
        :v-model="userEmail"
        :value="userEmail"
        type="text"
        @input="emailChange"
        placeholder="Enter your email"
      />
    </div>
    <div class="error">{{ error }}</div>
    <div class="submit-row">
      <div class="button submit" @click="submitClaim">Claim</div>
      <Loading v-if="isLoading" isCenter isContained />
    </div>
    <div class="subtext" @click="login">
      If you already have an account,
      <p>login</p>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useFirebase } from "@/revamp/composables";
import { emailValidator } from "@/revamp/utils";
import { Loading } from "@/revamp/components";
import { notifyStore } from "@/revamp/store";

export default {
  name: "User Claim",
  components: {
    Loading,
  },
  props: {
    submit: Function,
    container: String,
  },
  setup(props) {
    const userEmail = ref("");
    const containerID = ref("");
    const error = ref("");
    const APIService = useFirebase();
    const isLoading = ref(false);
    const Router = useRouter();
    const submitting = ref(false);

    watch(
      () => props.container,
      () => {
        containerID.value = props.container;
        userEmail.value = "";
      },
      { immediate: true }
    );

    function login() {
      Router.push("/");
    }

    function emailChange(e) {
      userEmail.value = e.target.value.toLowerCase().trim();
      e.target.value = userEmail.value;
    }

    function containerChange(e) {
      containerID.value = e.target.value.toUpperCase().trim();
      e.target.value = containerID.value;
    }

    async function submitClaim(e) {
      error.value = "";
      if (submitting.value) return;

      submitting.value = true;
      if (containerID.value == "" || userEmail.value == "") {
        error.value = "Please fill out both fields";
        submitting.value = false;
        return;
      }

      if (!emailValidator(userEmail.value)) {
        error.value = "Please Enter a valid email";
        submitting.value = false;
        return;
      }

      containerID.value = containerID.value.toUpperCase();
      userEmail.value = userEmail.value.toLowerCase().trim();

      isLoading.value = true;
      let response = await APIService.verifyCode(containerID.value);
      if (response == null || response == undefined) {
        error.value = "Please Check QR Code";
      } else if (!response.doesExist) {
        if (response.error && response.code == 30000) {
          error.value = "Please Check QR Code";
        } else if (response.error) {
          notifyStore.createModal(
            "Oops",
            "Something went wrong verifing your code, please wait a few minutes or reach out to Friendlier for help",
            [
              {
                id: "close",
                text: "Dismiss",
                action: "close",
              },
            ],
            true
          );
        }
      }
      isLoading.value = false;
      submitting.value = false;
      props.submit(containerID.value, userEmail.value);
    }

    return {
      // Methods
      emailChange,
      containerChange,
      submitClaim,
      login,
      // Data
      error,
      userEmail,
      containerID,
      isLoading,
      submitting,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";

.claim-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
  .title {
    font-size: 1.1rem;
    color: $sunflower;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }

  .submit-row {
    height: 75px;
    display: flex;
    padding: 15px 0px;
    justify-content: center;
    .submit {
      padding: 7px 12px;
      margin-right: 10px;
      background-color: $sunflower;
      border-radius: 10px;
      border: none;
      width: 40%;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .subtext {
    color: $cloud;
    width: 100%;
    text-align: center;

    p {
      text-decoration: underline;
      display: inline-block;
    }
  }

  .input-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .input {
      width: 80%;
      border-radius: 10px;
    }

    .label {
      margin-right: 10px;
      margin-bottom: 0;
      color: $sunflower;
    }
  }
  .error {
    color: red;
  }
}
</style>
