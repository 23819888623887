<template>
  <h2 class="is-size-2 mb-4 mt-2 title">{{ title }}</h2>
  <form>
    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-signature"></i>
        </span>
        <input type="text" required :placeholder="$t('emailUpdateForm.email')" v-model="email" class="is-size-4 is-right p-2" />
      </p>
      <div id="dummy-spacer" class="mt-4 has-text-danger error"></div>
    </div>
    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-signature"></i>
        </span>
        <input
          type="text"
          required
          :placeholder="$t('emailUpdateForm.new-email')"
          v-model="newEmail"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div id="dummy-spacer" class="mt-4 has-text-danger error"></div>
    </div>
    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-signature"></i>
        </span>
        <input
          type="text"
          required
          :placeholder="$t('emailUpdateForm.confirm-email')"
          v-model="confirmEmail"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div id="dummy-spacer" class="mt-4 has-text-danger error"></div>
    </div>
  </form>
  <button :onclick="validate" :disabled="locked" class="ocean-button is-bold fs-1-2">
    {{ buttonText }}
  </button>
  <div class="mt-4 has-text-danger error">{{ error }}</div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { emailValidator, emailMatch } from "@/revamp/utils";
import { useI18n } from "vue-i18n";

export default {
  props: {
    showEmail: Boolean,
    locked: Boolean,
    title: String,
    buttonText: String,
    emailVal: String,
    submit: Function,
  },
  setup(props) {
    const email = ref(props.emailVal ? props.emailVal : "");
    const emailError = ref("");
    const newEmail = ref("");
    const newEmailError = ref("");
    const confirmEmail = ref("");
    const confirmEmailError = ref("");
    const error = ref("");

    const { t } = useI18n({
      locale: "en",
      messages: {
        en: {
          validEmail: "Please enter a valid email",
          allFields: "Please complete all fields",
          fix: "Fix problems before submitting",
        },
        fr: {
          validEmail: "Veuillez saisir un courriel valide",
          allFields: "Veuillez remplir tous les champs",
          fix: "Réglez les problèmes avant de soumettre",
        },
      },
    });
    const msg = computed(() => t("validEmail", "allFields", "fix"));

    watch(email, () => {
      error.value = "";
      emailError.value = "";
      if (email.value == "") {
        emailError.value = "";
        return;
      }
      if (!emailValidator(email.value)) {
        emailError.value = t("validEmail");
      }
    });

    watch(newEmail, () => {
      error.value = "";
      if (!emailValidator(newEmail.value)) {
        newEmailError.value = t("validEmail");
      }
      confirmEmailError.value = emailMatch(newEmail.value, confirmEmail.value);
    });
    watch(confirmEmail, () => {
      error.value = "";
      if (!emailValidator(confirmEmail.value)) {
        confirmEmailError.value = t("validEmail");
      }
      confirmEmailError.value = emailMatch(newEmail.value, confirmEmail.value);
    });
    const validate = async () => {
      error.value = "";
      if (email.value == "" || newEmail.value == "" || confirmEmail.value == "") {
        error.value = t("allFields");
        return;
      }
      if (
        emailError.value != "" ||
        confirmEmailError.value != ""
      ) {
        error.value = t("confirm-email");
        return;
      }

      props.submit({ email: email.value, newEmail: newEmail.value });
    };
    return {
      // Data
      error,
      email,
      emailError,
      newEmail,
      newEmailError,
      confirmEmail,
      confirmEmailError,
      // Methods
      t,
      msg,
      validate,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.title {
  color: $sunflower;
}

span.icon {
  width: 20px;
}
</style>
