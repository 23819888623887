<template>
  <div class="content">
    <Background>
      <FriendlierCard>
        <div class="padded-block">
          <div class="logo"><img :src="FriendlierPrimaryOcean" /></div>
          <Loading v-if="isLoading" isCenter isFull />
          <div v-if="!isLoading" class="text-content">
            <div v-if="validity == null"></div>
            <div v-if="validity == true && verified == true">
              <p class="is-cloud mt-3">
                Thank you for verifying your email,<br />you can now login to use the complete Friendlier System, you may need
                to log out of the mobile app for it to take affect
              </p>
              <router-link class="sunflower-button is-bold" to="/">Login</router-link>
            </div>
            <div v-if="validity == false">
              <div v-if="!isSent">
                <p class="is-cloud mt-3">Your link has expired, please re-enter your email to send another link</p>
                <div class="friendlier-field">
                  <p class="control">
                    <span class="icon is-medium is-left mr-3">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <input type="email" required placeholder="email" v-model="email" class="is-size-4 is-right p-2" />
                  </p>
                  <div class="mt-4 has-text-danger error">{{ emailError }}</div>
                </div>
                <div class="sunflower-button is-bold" @click="send">Send</div>
              </div>
              <div v-if="isSent" class="is-cloud">
                Check your inbox for an email in a few moments, make sure to check your junkmail as well.
              </div>
            </div>
          </div>
        </div>
      </FriendlierCard>
    </Background>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useFirebase } from "@/revamp/composables";
import { Loading, FriendlierCard, Background } from "@/revamp/components";
import { FriendlierPrimaryOcean } from "@/revamp/assets/images";
import { emailValidator, getQueryParams, logError } from "@/revamp/utils";
import { notifyStore } from "@/revamp/store";

export default {
  name: "VerifyEmail",
  components: {
    Loading,
    FriendlierCard,
    Background,
  },
  setup() {
    let Router = useRouter();
    let APIService = useFirebase();
    let validity = ref(null);
    let verified = ref(false);
    let isLoading = ref(true);
    let isSent = ref(false);
    let email = ref("");
    let emailError = ref("");

    watch(email, () => {
      emailError.value = "";
      if (email.value == "") return;
      if (!emailValidator(email.value)) emailError.value = "Enter a valid email";
    });

    onMounted(getParams);

    async function getParams() {
      let params = await getQueryParams(true, "/verifyEmail");
      if (JSON.stringify(params) == "{}") {
        Router.push("FourOhFour");
        return;
      }
      isValid(params["user"]);
    }

    async function isValid(verifyLink) {
      try {
        const checkResponse = await APIService.checkVerificationLink(verifyLink);
        if (checkResponse == null) {
          throw new Error("Failed to fetch");
        }

        if (checkResponse.error == true) {
          if (checkResponse.code == 20000) {
            Router.push("FourOhFour");
            return;
          }
          throw new Error("Server Error");
        }

        validity.value = checkResponse.isValid;
        if (checkResponse.isValid) {
          const verifyResponse = await APIService.verifyUser(checkResponse.email);
          if (!verifyResponse) {
            validity.value = false;
            throw new Error(`Failed to Verify ${checkResponse.email}`);
          }
          verified.value = true;
        }
      } catch (e) {
        logError(`VerifyEmail: Failed to verify user. ${e.toString()}`);
        notifyStore.createModal(
          "Oops",
          "We were unable to verify your email. This is likely our fault. Please reach out to us for manual help",
          [
            {
              id: "close",
              text: "Dismiss",
              action: "close",
            },
          ],
          true
        );
      }
      isLoading.value = false;
    }

    async function send() {
      try {
        if (emailError.value !== "") return;
        if (email.value == "") {
          emailError.value = "Enter a valid email";
          return;
        }
        isLoading.value = true;

        let response = await APIService.sendVerificationEmail(email.value);
        if (response == null) {
          throw new Error("Server Error");
        }

        if (response.error) {
          if (response.code != 20000) {
            throw new Error("Server Error");
          }
        }
        isSent.value = true;
      } catch (e) {
        logError(`VerifyEmail.send: failed to send verification email ${email.value}`);
        notifyStore.createModal(
          "Oops",
          "We were unable to send a verification email, please try again later or reach out to us for help.",
          [
            {
              id: "close",
              text: "Dismiss",
              action: "close",
            },
          ],
          true
        );
      }
      isLoading.value = false;
    }

    return {
      // Images
      FriendlierPrimaryOcean,
      // Data
      isLoading,
      validity,
      verified,
      isSent,
      email,
      emailError,
      // Methods
      send,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.logo {
  max-width: 30%;
  min-width: 300px;
}
</style>
