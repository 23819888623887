<template>
  <CustomerNavbar />

  <div class="container mt-6">
    <p class="is-family-secondary is-size-3 pt-6 mb-4 ml-3">
      {{ isEmailUpdate ? $t("displayVue.change-email") : $t("displayVue.change-password") }}
    </p>
    <form>
      <p class="is-size-6 mb-3 ml-3">{{ isEmailUpdate ? $t("changeEmailRequest.security") : $t("changePassword.security") }}</p>
      <p>
        {{ isEmailUpdate ? $t("displayVue.change-email") : $t("displayVue.change-password") }}
      </p>
      <p v-if="message">{{}}</p>
      <!-- removed "message" from brackets, come back later to fix this page -->
      <p v-if="error">{{}}</p>
      <!-- removed "error" from brackets, come back later to fix this page -->
      <button type="submit" @click.prevent="handleChange" class="button is-info mt-1 is-size-6 ml-3 mb-5">
        {{ $t("proceed") }}
      </button>
    </form>
    <div v-html="message" class="ml-3"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import getUser from "../composables/getUser";
import { AuthService } from "@/revamp/firebase/config";
import { useI18n } from "vue-i18n";
import { CustomerNavbar } from "@/components";
import { useRouter, useRoute } from "vue-router";
import request from "../revamp/utils/request";
const message = ref("");
const error = ref("");

const { t } = useI18n({
  locale: "en",
  messages: {
    en: {
      check: "<strong>Check your inbox! We've sent you an email with a link to change your password.</strong>",
    },
    fr: {
      check:
        "<strong>Vérifiez votre boîte de réception ! Nous vous avons envoyé un courriel contenant un lien pour changer votre mot de passe.</strong>",
    },
  },
});

const msg = computed(() => t("check"));

const { user } = getUser();
const router = useRouter();
const route = useRoute();
const loading = ref(true);

onMounted(async () => {
  init();
});

async function init() {
  if (user.value == null || user.value == undefined) {
    router.push({ name: "Welcome" });
    return;
  }
  loading.value = false;
}

watch(
  user,
  () => {
    if (!user.value) {
      router.push({ name: "Welcome" });
    }
  },
  { immidiate: true }
);
const isEmailUpdate = computed(() => route.name === "ChangeEmailRequest");

const handleChange = async () => {
  try {
    if (isEmailUpdate.value) {
      await sendEmailResetEmail();
    } else {
      await sendPasswordResetEmail();
    }
  } catch (err) {
    error.value = err.message;
  }
};
const sendEmailResetEmail = async () => {
  try {
    await request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/SendUserUpdateEmail`,
      "POST",
      null,
      "sendEmailResetEmail",
      true
    );
    message.value = t("check");
    await AuthService.signOut();
  } catch (err) {
    error.value = err.message;
  }
};
const sendPasswordResetEmail = async () => {
  try {
    await AuthService.sendPasswordResetEmail(user.value.email);
    message.value = t("check");
    await AuthService.signOut();
  } catch (err) {
    error.value = err.message;
  }
};
</script>

<style></style>
