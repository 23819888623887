import { AuthService, DatabaseService, timestamp, FIREBASE_FUNCTION_HEADERS, REQUEST, adjustVal } from "@/revamp/firebase/config";
import { logError } from "@/revamp/utils";

async function addPaymentRequest(data) {
  try {
    await DatabaseService.collection('paymentRequests').add(data);
  } catch (err) {
    logError(`totalFunctions.updateTotalDonated: Failed to add payment request. ${JSON.stringify(data)}. ${err.toString()}.`)
  }
}

async function updateOrgTotalDonated(donated) {
  try {
    await DatabaseService.collection("totals").doc("transactions").set({ totalDonated: adjustVal(donated) }, { merge: true });
  } catch (err) {
    logError(`totalFunctions.updateOrgTotalDonated: Failed to update donated total. ${donated}. ${err.toString()}`)
  }
}

async function updateCharityTotalDonated(charityID, donated) {
  try {
    await DatabaseService.collection("charities").doc(charityID).set({
      totalCharity: adjustVal(donated)
    }, {merge: true})
  } catch (err) {
    logError(`totalFunctions.updateCharityTotalDonated: Failed to update donated total. ${err.toString()}`)
  }
}

async function getRCReturnCount() {
  try {
    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/getRCReturnCount`, {
      method: "GET",
      headers: FIREBASE_FUNCTION_HEADERS,
    });

    console.log(response);
    return await response.json();
  } catch (e) {
    logError(`QRCodeFunctions.verifyCode: Failed to fetch count. ${e.toString()}`);
    return null;
  }
}

async function codeVal(code) {
  try {
    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/code`, {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ id: code, fields: ['spun','distributed'] }),
    });
    return await response.json();
  } catch (e) {
    logError(`QRCodeFunctions.verifyCode: Failed to fetch count. ${e.toString()}`);
    return null;
  }
}

async function RCWinner(code, email, win, name) {
  try {
    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/RCWin`, {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify({ code: code, email: email, win: win, name: name }),
    });
    return await response.json();
  } catch (e) {
    logError(`QRCodeFunctions.verifyCode: Failed to fetch count. ${e.toString()}`);
    return null;
  }
}

const totals_functions = {
  addPaymentRequest,
  updateOrgTotalDonated,
  updateCharityTotalDonated,
  getRCReturnCount,
  RCWinner,
  codeVal
};

export default totals_functions;