<template>
  <div class="content">
    <Background>
      <FriendlierCard>
        <div class="padded-block">
          <div class="logo"><img :src="FriendlierPrimaryOcean" /></div>
          <h3 class="is-cloud not-found">{{ message }}</h3>

          <v-row v-if="showDownloadButtons" style="padding-top: 35px">
            <v-col style="padding: 0">
              <img :src="IOS" @click="openIOSStore" />
            </v-col>
            <v-col style="padding: 0">
              <img :src="Android" @click="openAndroidStore" />
            </v-col>
          </v-row>
        </div>
      </FriendlierCard>
    </Background>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Background, FriendlierCard } from "@/revamp/components";
import { FriendlierPrimaryOcean } from "@/revamp/assets/images";
import { IOS } from "@/revamp/assets/images";
import { Android } from "@/revamp/assets/images";

export default {
  components: {
    Background,
    FriendlierCard,
  },
  setup() {
    const router = useRouter();
    const message = ref(
      "We're Sorry, the page you are looking for is not found"
    );
    const showDownloadButtons = ref(false);

    const openIOSStore = () => {
      window.location.href =
        "https://apps.apple.com/app/apple-store/id1533593665";
    };

    const openAndroidStore = () => {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.afriendliercompany.afcbeta";
    };

    onMounted(() => {
      const fromRoute = router.currentRoute.value.fullPath;

      if (/\/return|\/null|\/[a-zA-Z0-9]{5}/.test(fromRoute)) {
        message.value = "Click the buttons to download the app.";
        showDownloadButtons.value = true;

        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.afriendliercompany.afcbeta";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          window.location.href =
            "https://apps.apple.com/app/apple-store/id1533593665";
        } else {
          console.log("Not a mobile device, no redirection.");
        }
      }
    });

    return {
      // Images
      FriendlierPrimaryOcean,
      message,
      showDownloadButtons,
      IOS,
      Android,
      openIOSStore,
      openAndroidStore,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";
@import "@/revamp/assets/scss/variables.scss";

.logo {
  max-width: 30%;
  min-width: 300px;
}

h3.not-found {
  line-height: 1.5;
}
</style>
