//function to get the current logged in user

import { ref } from "vue"
import { AuthService } from "@/revamp/firebase/config"

const user = ref(AuthService.currentUser)

AuthService.onAuthStateChanged((_user)=>{
  user.value = _user
})

const getUser = () =>{
  return {user}
}

export default getUser