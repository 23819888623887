import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";

import { logError } from "@/revamp/utils";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

//init firebase
firebase.initializeApp(firebaseConfig);

const AuthService = firebase.auth();
const DatabaseService = firebase.firestore();
const projectStorage = firebase.storage();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);
const incrementHalf = firebase.firestore.FieldValue.increment(0.5);
const decrementHalf = firebase.firestore.FieldValue.increment(-0.5);
const adjustVal = firebase.firestore.FieldValue.increment;
const APIKey = process.env.VUE_APP_FIREBASE_API_KEY;

const FIREBASE_FUNCTION_HEADERS = {
  "x-api-key": process.env.VUE_APP_FRIENDLIER_API_KEY,
  ContentType: "application/json",
};

const REQUEST = async (path, bodyObj, context) => {
  try {
    let response = await fetch(process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + path, {
      method: "POST",
      headers: FIREBASE_FUNCTION_HEADERS,
      body: JSON.stringify(bodyObj),
    });
    return await response.json();
  } catch (e) {
    logError(`${context}: ${e.toString()}`);
    return null;
  }
};

export {
  AuthService,
  DatabaseService,
  timestamp,
  projectStorage,
  increment,
  decrement,
  incrementHalf,
  decrementHalf,
  APIKey,
  FIREBASE_FUNCTION_HEADERS,
  REQUEST,
  adjustVal,
};
