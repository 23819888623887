<template>
  <div class="claim-container">
    <div class="title">Claim a Container:</div>
    <div class="input-container">
      <div class="label">Camera:</div>
      <select class="selected-constaints" v-model="selectedConstraints">
        <option v-for="option in constraintOptions" :key="option.label" :value="option.constraints">
          {{ option.label }}
        </option>
      </select>
      <QrcodeStream
        v-if="isScanning"
        :constraints="selectedConstraints"
        @error="onError"
        @detect="onDetect"
        @camera-on="onCameraReady"
      />
      <button @click="isScanning = !isScanning" class="button sunflower-button" :class="{ isScanning: isScanning }">
        Scan QR Code
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { logError } from "@/revamp/utils";
import { QrcodeStream } from "vue-qrcode-reader";

const props = defineProps({
  container: String,
});

const emits = defineEmits(["submit"]);

const isScanning = ref(false);

const selectedConstraints = ref({ facingMode: "environment" });
const defaultConstraintOptions = [
  { label: "rear camera", constraints: { facingMode: "environment" } },
  { label: "front camera", constraints: { facingMode: "user" } },
];
const constraintOptions = ref(defaultConstraintOptions);
const result = ref("");

const containerID = ref("");
const error = ref("");

async function onCameraReady() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(({ kind }) => kind === "videoinput");

  constraintOptions.value = [
    ...defaultConstraintOptions,
    ...videoDevices.map(({ deviceId, label }) => ({
      label: `${label} (ID: ${deviceId})`,
      constraints: { deviceId },
    })),
  ];

  error.value = "";
}

function onDetect(content) {
  try {
    result.value = content[0];
    containerID.value = content[0].rawValue;
    isScanning.value = false;
    emits("submit", containerID.value);
  } catch (e) {
    error.value = "Invalid QR Code";
    logError(e);
  }
}

function onError(err) {
  error.value = `[${err.name}]: `;

  if (err.name === "NotAllowedError") {
    error.value += "you need to grant camera access permission";
  } else if (err.name === "NotFoundError") {
    error.value += "no camera on this device";
  } else if (err.name === "NotSupportedError") {
    error.value += "secure context required (HTTPS, localhost)";
  } else if (err.name === "NotReadableError") {
    error.value += "is the camera already in use?";
  } else if (err.name === "OverconstrainedError") {
    error.value += "installed cameras are not suitable";
  } else if (err.name === "StreamApiNotSupportedError") {
    error.value += "Stream API is not supported in this browser";
  } else if (err.name === "InsecureContextError") {
    error.value += "Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
  } else {
    error.value += err.message;
  }

  logError(error.value);
}
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";

.claim-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;

  .title {
    font-size: 1.1rem;
    color: $sunflower;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }

  .submit-row {
    height: 75px;
    display: flex;
    padding: 15px 0px;
    justify-content: center;
  }

  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .input {
      width: 80%;
      border-radius: 10px;
    }

    .label {
      color: $sunflower;
      margin-bottom: 30px;
    }

    .selected-constaints {
      width: 70%;
      border-radius: 10px;
      padding: 5px;
      background-color: $cloud;
      margin-bottom: 30px;
    }

    .error {
      width: 100%;
    }
    .decode-result {
      width: 100%;
      margin-bottom: 10px;
      text-align: left;
      color: $sunflower;
    }
    .sunflower-button {
      margin: 0 auto 0px auto;
      width: 60%;

      &.isScanning {
        margin-top: 20px;
      }
    }
  }
}

.sunflower-button {
  color: $denim;
  background-color: $sunflower;

  padding: 7px 12px;
  border-radius: 10px;
  border: none;
  width: 40%;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}
</style>
