import { logError } from "@/revamp/utils";
import { FIREBASE_FUNCTION_HEADERS } from "@/revamp/firebase/config";

async function outletQuickClaim(outlet, codeID) {
  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/outletQuickClaim`,
      {
        method: "POST",
        headers: FIREBASE_FUNCTION_HEADERS,
        body: JSON.stringify({
          outlet: outlet,
          id: codeID
        }),
      }
    );
    return await response.json();
  } catch (e) {
    logError(`OutletFunctions.outletQuickClaim Failed to claim code ${codeID} ${e.toString()}`);
    return null;
  }
}

const Outlet_functions = {
  outletQuickClaim,
};

export default Outlet_functions;