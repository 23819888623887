<template>
  <div class="card mt-5 claim-card">
    <div class="card-image p-6">
      <figure class="image is-4by3"></figure>
      <!-- <figure class="image is-4by3" :style="{ backgroundImage: 'url(' + appDownloadURL + ')' }"></figure> -->
    </div>
    <div class="card-content pt-0">
      <div class="media">
        <div class="media-content">
          <!-- <p class="is-size-4 has-text-info has-text-centered is-family-secondary mb-1">
            {{ $t("claimContainer.enter") }}
          </p>
          <p id="claim_content" class="has-text-info has-text-centered mb-2">
            {{ $t("claimContainer.available") }}
          </p> -->
          <!-- <div class="app-badges">
            <a
              :href="
                isMobile()
                  ? 'https://afcbeta.page.link/download'
                  : 'https://play.google.com/store/apps/details?id=com.afriendliercompany.afcbeta'
              "
              target="_blank"
              ref="no-referrer"
            >
              <img
                :src="locale == 'en' ? GooglePlayBadge_EN : GooglePlayBadge_FR"
                :alt="locale == 'en' ? 'Download on Google Play' : 'Disponible sur Google Play'"
              />
            </a>
            <a
              :href="
                isMobile() ? 'https://afcbeta.page.link/download' : 'https://apps.apple.com/ca/app/friendlier/id1533593665'
              "
              target="_blank"
              ref="no-referrer"
            >
              <img
                :src="locale == 'en' ? AppStoreBadge_EN : AppStoreBadge_FR"
                :alt="locale == 'en' ? 'Download on the Apple App Store' : 'Disponible sur l\'Apple App Store'"
            /></a>
          </div> -->
          <p class="is-size-4 has-text-info has-text-centered is-family-secondary mb-1">
            {{ $t("claimContainer.claim") }}
          </p>
          <form @submit.prevent="handleClaim" autocomplete="off">
            <input autocomplete="false" name="hidden" type="text" style="display: none" />
            <div class="level mt-2 mb-1">
              <input
                type="text"
                placeholder="CXXXXXX"
                v-model="code"
                required
                class="has-text-centered is-size-4-desktop pb-1 pt-1"
                id="claim_id"
              />
            </div>
            <div class="has-text-centered pt-2 pb-1">
              <button type="submit" :disabled="submitting" class="button is-info is-size-6 pt-1 pb-1" id="claim_button">
                <p class="is-family-secondary is-size-6 pb-2 pt-2">
                  {{ $t("enter") }}
                </p>
              </button>
            </div>
            <div v-if="message.type == 'err'">
              <p class="has-text-centered is-size-5 has-text-danger-dark">
                {{ message.msg }}
              </p>
            </div>
            <div v-if="message.type == 'msg'" class="has-text-centered is-size-5 has-text-success">
              <p>{{ message.msg }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { projectFirestore, timestamp, increment, adjustVal } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { ref, computed } from "vue";
import { logError } from "@/revamp/utils";
import { useI18n } from "vue-i18n";
import QRious from "qrious";
import { useFirebase } from "@/composables";
import { GooglePlayBadge_FR, GooglePlayBadge_EN, AppStoreBadge_EN, AppStoreBadge_FR } from "@/assets";

const props = defineProps({
  verify: Function,
  sendVerification: Function,
});
const message = ref({ type: null, msg: "" });
const code = ref("");
const activeCode = ref();
const submitting = ref(false);
const { user } = getUser();
const _fb = useFirebase();

const { t, locale } = useI18n({
  locale: "en",
  messages: {
    en: {
      send: "Send Verification Email",
      close: "Close",
      notice: "Notice",
      alreadyClaimed: "Container has already been claimed please place in a return bin",
      oops: "Oops, something went wrong. Please try again",
      verify: "Please Verify Email",
      verifyEmail: "Please verify your email before claiming a container.",
      success: "Container successfully claimed! Please place in a return bin.",
      tryAgain: "Something went wrong, please try again later",
    },
    fr: {
      send: "Envoyer le courriel de vérification",
      close: "Fermer",
      notice: "Avis",
      alreadyClaimed: "Le contenant a déjà été réclamé veuillez le placer dans un bac de collecte.",
      oops: "Oups, un problème est survenu. Veuillez réessayer",
      verify: "Veuillez vérifier votre courriel",
      verifyEmail: "Veuillez vérifier votre courriel avant de réclamer un contenant.",
      success: "Le contenant a été réclamé avec succès ! Veuillez le placer dans un bac de collecte.",
      tryAgain: "Un problème est survenu, veuillez réessayer plus tard",
    },
  },
});
const msg = computed(() =>
  t("alreadyClaimed", "oops", "verify", "verifyEmail", "success", "tryAgain", "notice", "close", "send")
);

const appDownload = new QRious({
  size: 200,
  value: "https://afcbeta.page.link/download",
  level: "H",
});
const appDownloadURL = ref(appDownload.toDataURL());

const isMobile = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

//Check if QR codes are valid
const verifyQR = async () => {
  try {
    activeCode.value = null;
    let qrCodeRef = projectFirestore.collection("QRCodes").doc(code.value);

    let qrCodeSnap = await qrCodeRef.get();

    if (qrCodeSnap.exists) {
      let qrCodeData = qrCodeSnap.data();
      activeCode.value = {
        ref: qrCodeRef,
        snap: qrCodeSnap,
        data: qrCodeData,
      };

      if (qrCodeData.isReturned) {
        message.value = {
          type: "err",
          msg: t("alreadyClaimed"),
        };
        return false;
      } else {
        return true;
      }
    } else {
      logError(`ClaimContainer.VerifyQR: Code ${code.value} does not exist`);
      message.value = {
        type: "err",
        msg: t("oops"),
      };
      return false;
    }
  } catch (e) {
    logError(`ClaimContainer.VerifyQR: Failed to verify code ${code.value}. ${e.toString()}`);
    message.value = {
      type: "err",
      msg: t("oops"),
    };
    return false;
  }
};

//Get user current pending balance
const getUserDetails = async () => {
  const userDocRef = projectFirestore.collection("users").doc(user.value.uid);
  return (await userDocRef.get()).data();
};

const handleClaim = async () => {
  submitting.value = true;

  message.value = { type: null, msg: "" };

  if (!user.value.emailVerified) {
    message.value = { type: "err", msg: t("verify") };
    props.verify({
      title: t("notice"),
      body: t("verifyEmail"),
      buttons: [
        {
          text: t("close"),
          action: () => {
            props.verify({});
          },
        },
        {
          text: t("send"),
          action: () => {
            props.verify({});
            props.sendVerification();
          },
        },
      ],
    });
    return;
  }

  code.value = code.value.toUpperCase();

  if (await verifyQR()) {
    try {
      let userData = await getUserDetails();

      let deposit = await _fb.lookupDeposit(activeCode.value);

      //Add a new collection to users
      let newClaimId = await projectFirestore
        .collection("users")
        .doc(user.value.uid)
        .collection("scans")
        .add({
          timeScanned: timestamp(),
          isConfirmed: false,
          containerID: activeCode.value["ref"].id,
          deposit: deposit ?? 0.5,
          location: activeCode.value["data"].distributed ? activeCode.value["data"].distributed : null,
          source: "manual",
          codeForm: "code",
          platform: "web",
        });

      await projectFirestore.collection("QRCodes").doc(activeCode.value["ref"].id).set(
        {
          isReturned: true,
          uid: user.value.uid,
          scanReference: newClaimId.id,
        },
        { merge: true }
      );

      await projectFirestore
        .collection("QRCodes")
        .doc(activeCode.value["ref"].id)
        .collection("story")
        .add({
          type: "scan",
          uid: user.value.uid,
          scanReference: newClaimId.id,
          time: timestamp(),
          location: activeCode.value["data"].distributed ? activeCode.value["data"].distributed : null,
          boxId: activeCode.value["data"].boxId ? activeCode.value["data"].boxId : null,
          source: "manual",
          codeForm: "code",
          platform: "web",
        });

      try {
        let userUpdate = { totalUnconfirmed: increment };
        let distributed = activeCode.value["data"].distributed;
        let restaurant;
        if (distributed != null && distributed != undefined && distributed != "") {
          restaurant = await projectFirestore.collection("restaurants").doc(activeCode.value["data"].distributed).get();
        }

        if (!restaurant || !restaurant.data().depIncluded) {
          userUpdate["pendingBalance"] = adjustVal(activeCode.value["data"].deposit);
        }
        await projectFirestore.collection("users").doc(user.value.uid).update(userUpdate);
      } catch (err) {
        logError(
          `Failed to update user ${user.value.uid} with container ${activeCode.value["data"].displayCode} ${err.toString()}`
        );
      }

      await projectFirestore.collection("totals").doc("deposits").update({ totalScans: increment });

      code.value = "";
      activeCode.value = null;
      message.value = {
        type: "msg",
        msg: t("success"),
      };
    } catch (err) {
      logError(`ClaimContainer.HandleClaim: Failed to claim container. ${err.toString()}`);
      message.value = {
        type: "err",
        msg: t("oops"),
      };
    }
  }

  submitting.value = false;
};
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";

#claim_id {
  width: 100%;
}
#claim_button {
  background-color: #032673;
}

.claim-card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: darken($cloud, 5%);

  .card-image {
    figure {
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../../revamp/assets/images/icons/denim/friendlier-icons-68.svg");
    }
  }

  .card-content {
    .media {
      .media-content {
        .app-badges {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          width: 100%;

          a {
            display: inline-flex;
            align-items: center;
            width: 40%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
