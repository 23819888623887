<template>
  <div class="impact-container">
    <!-- <div class="title">Thank-you for being the part of our movement to save the planet!</div> -->
    <!-- <div class="sub-title">You just saved:</div>
    <div class="sub-title">{{ (numContainers * 0.058 * 2.20462).toFixed(2) }} pounds of plastic waste</div> -->
    <!-- <div class="impact-statements"> -->
    <!-- <div>{{ (numContainers * 0.058 * 2.20462).toFixed(2) }} pounds of plastic waste</div> -->
    <hr />
    <div class="textItems">
      download the friendlier app and sign up to manage your return
    </div>
    <div>
      <v-row style="padding-top: 35px">
        <v-col style="padding: 0">
          <img :src="IOS" @click="openIOSStore" />
        </v-col>
        <v-col style="padding: 0">
          <img :src="Android" @click="openAndroidStore" />
        </v-col>
      </v-row>
    </div>

    <!-- <div class="spacer">
        <span />
        or
        <span />
      </div>
      <div>{{ (numContainers * 0.168 * 2.20462).toFixed(2) }} pounds of CO2 emissions</div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { FriendlierPrimaryCloud } from "@/revamp/assets/images";
import { IOS } from "@/revamp/assets/images";
import { Android } from "@/revamp/assets/images";

export default {
  name: "ContainerImpact",
  props: {
    containers: Array,
  },
  setup(props) {
    const numContainers = ref(0);

    const openIOSStore = () => {
      window.location.href =
        "https://apps.apple.com/app/apple-store/id1533593665";
    };

    const openAndroidStore = () => {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.afriendliercompany.afcbeta";
    };

    function init() {
      numContainers.value = props.containers.length;
    }

    onMounted(async () => {
      init();
    });

    return {
      // Methods
      // Data
      numContainers,
      FriendlierPrimaryCloud,
      IOS,
      Android,
      openIOSStore,
      openAndroidStore,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";
.impact-container {
  width: 100%;
  margin-top: 10px;

  .textItems {
    color: white;
    font-weight: 600;
  }

  .title {
    color: $sunflower;
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: bold;
  }

  .sub-title {
    color: $sunflower;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .impact-statements {
    display: inline-flex;
    flex-direction: column;
    font-size: 1.1rem;
    padding-top: 15px;

    div {
      width: 100%;
      text-align: center;
      color: $sunflower;
      display: inline-block;
    }
    .spacer {
      color: $sunrise;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      span {
        height: 2px;
        background-color: $denim;
        width: 15%;
        margin: 15px;
      }
    }
  }
}
</style>
