<template>
  <FriendlierCard>
    <div class="padded-block">
      <div class="logo"><img :src="FriendlierPrimaryOcean" /></div>
      <!-- <h2 class="is-size-2 mb-4 mt-2 title">Login</h2> -->
      <h2 class="is-size-2 mb-4 mt-2 title">{{ $t("loginVue.login") }}</h2>
      <form>
        <div class="friendlier-field">
          <p class="control">
            <span class="icon is-medium is-left mr-3">
              <i class="fas fa-envelope"></i>
            </span>
            <input type="email" required :placeholder="$t('loginVue.email')" v-model="email" class="is-size-4 is-right p-2" />
          </p>
        </div>
        <div class="friendlier-field">
          <p class="control">
            <span class="icon is-medium is-left mr-3">
              <i class="fas fa-key"></i>
            </span>
            <input
              type="password"
              required
              :placeholder="$t('loginVue.password')"
              v-model="password"
              class="is-size-4 is-right p-2"
            />
          </p>
        </div>

        <button @click.prevent="login" class="ocean-button mt-3 is-bold fs-1-2">
          {{ $t("loginVue.log-in") }}
        </button>
        <div class="mt-3 has-text-danger">{{ errorMessage }}</div>
      </form>
      <p class="mt-3">
        <span :onclick="forgot"
          ><a class="is-cloud">{{ $t("loginVue.forgot-password") }}</a>
        </span>
      </p>
      <hr class="is-back-cloud" />
      <p class="has-text-weight-light mb-2 is-cloud">
        {{ $t("loginVue.no-account") }}
      </p>
      <span class="ocean-button is-bold fs-1-2" :onclick="signup">
        {{ $t("loginVue.sign-up") }}
      </span>
      <div class="locale-switcher">
        <LocaleSwitcher />
      </div>
    </div>
  </FriendlierCard>
</template>
<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { ref, computed } from "vue";
import { useFirebase } from "@/revamp/composables";
import { FriendlierCard } from "@/revamp/components";
import { FriendlierPrimaryOcean } from "@/revamp/assets/images";
import { useRouter } from "vue-router";

import { useI18n } from "vue-i18n";

export default {
  name: "Login",
  components: {
    FriendlierCard,
    LocaleSwitcher,
  },
  props: {
    signup: Function,
    forgot: Function,
  },
  setup() {
    const errorMessage = ref("");
    const email = ref("");
    const password = ref("");
    const APIService = useFirebase();
    const Router = useRouter();

    const { t } = useI18n({
      locale: "en",
      messages: {
        en: {
          invalid: "Invalid email or password",
        },
        fr: {
          invalid: "Mot de passe ou courriel invalide",
        },
      },
    });

    const msg = computed(() => t("invalid"));

    async function login() {
      if (email.value == "" || password.value == "") {
        //errorMessage.value = this.$t('loginVue.err-enter');
        //errorMessage.value = i18n.t('err-enter');
        errorMessage.value = t("invalid");

        return;
      }
      email.value = email.value.toLowerCase().trim();
      try {
        await APIService.login(email.value, password.value);
        Router.push("Dashboard");
      } catch (e) {
        //errorMessage.value = $t('loginVue.err-invalid');
        errorMessage.value = t("invalid");
        //errorMessage.value = i18n.t('err-invalid');
      }
    }

    return {
      // Data
      errorMessage,
      password,
      email,
      // Methods
      login,
      // Images
      FriendlierPrimaryOcean,

      t,
      msg,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.padded-block {
  padding: 20px;
  width: 100%;
  text-align: center;
}

.title {
  color: $sunflower;
}

.logo {
  max-width: 30%;
  min-width: 300px;
  margin: 0 auto;
}

.dropdown {
  outline: pink;
  background-color: pink;
  // appearance: none;
  // color: #4a4a4a;
  color: red;
  font-size: 1.25rem !important;
  // padding: 0.5rem 0.75rem;
  padding-top: 0.5rem;
  // padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}
</style>
