<template>
  <div class="">
    <div class="header-row is-justify-content-center mb-3">
      <p class="is-family-secondary is-size-4 is-denim">
        {{ $t("donateRequest.donate") }}
      </p>
      <Loading v-if="loading == true" />
    </div>

    <p class="has-text-weight-light mb-4 is-denim has-text-centered">
      {{ $t("donateRequest.donate-sub") }}
    </p>
    <carousel
      v-if="Object.keys(charities).length > 0"
      :items-to-show="numSlides"
      :wrap-around="true"
      class="charity-carousel"
      snapAlign="start"
    >
      <slide v-for="el in charities" :key="el.id" class="charity-slide">
        <div class="card">
          <div class="card-charity-image">
            <img :src="el.logo" class="charity-image" data-target="modal-image2" />
          </div>
          <div class="card-content">
            <div class="has-text-centered">
              <h4 class="has-text-centered">{{ el.charityName }}</h4>
              <p>
                {{ el.descriptionLang[$i18n.locale] }}
                <a :href="el.websiteUrl" target="_blank">{{ $t("donateRequest.learn-more") }}</a>
              </p>
            </div>
          </div>
          <div class="card-footer has-text-centered">
            <button class="button level is-info" @click.prevent="(e) => handleDonate(e, el.id)">
              {{ $t("donateRequest.donate-to", { name: el.charityName }) }}
            </button>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script setup>
//imports
import { ref, onMounted, watch, computed, onUnmounted } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

import { projectFirestore, timestamp } from "@/firebase/config";
import { useFirebase } from "@/revamp/composables";
import getUser from "@/composables/getUser";
import { Loading } from "@/revamp/components";
import { logError } from "@/revamp/utils";
import { useI18n } from "vue-i18n";

const props = defineProps({
  createModal: Function,
  sendVerification: Function,
});
const emit = defineEmits(["balance"]);

const _fb = useFirebase();

const user = ref();
let charities = ref({});
const loading = ref(false);
const numSlides = ref(3.5);

const { t } = useI18n({
  locale: "en",
  messages: {
    en: {
      send: "Send Verification Email",
      close: "Close",
      verifyEmail: "Please Verify Email",
      notice: "Notice",
      success: "Success",
      dismiss: "Dismiss",
      failure: "Failure",
      donate: "Donate your balance to one of our recommended charities",
      learnMore: "Learn more",
      donateTo: "Donate my deposits to { name }",
      temporary: "Temporarily Down",
      notEnough: "You don't have enough in your balance to donate",
      serviceDown: "Please note, our dontation service is currently down - we are working to fix this quickly! Thank you.",
      confirmation: "Confirmation",
      proceed: "will be donated to this charity. Do you wish to proceed?",
      verify: "Please verify your email before donating your deposit.",
    },
    fr: {
      send: "Envoyer le courriel de vérification",
      close: "Fermer",
      verifyEmail: "Veuillez vérifier votre courriel",
      notice: "Avis",
      success: "Succès",
      dismiss: "Rejeter",
      failure: "Échec",
      donate: "Faites don de votre solde à l'une des organisations de bienfaisance que nous recommandons",
      learnMore: "En savoir plus",
      donateTo: "Faire don de mes consignes à { name }",
      temporary: "Temporairement en panne",
      notEnough: "You don't have enough in your balance to donate",
      serviceDown:
        "Veuillez noter que notre service de donation est actuellement en panne - nous travaillons à réparer cela rapidement! Nous vous remercions.",
      confirmation: "Confirmation",
      proceed: "seront versés à cette œuvre de bienfaisance. Souhaitez-vous poursuivre?",
      verify: "Veuillez vérifier votre courriel avant de faire don de votre consigne.",
    },
  },
});

const msg = computed(() =>
  t(
    "send",
    "close",
    "verifyEmail",
    "notice",
    "learnMore",
    "success",
    "dismiss",
    "failure",
    "donateTo",
    "donate",
    "temporary",
    "serviceDown",
    "confirmation",
    "proceed",
    "verify"
  )
);

//setup data
const init = async () => {
  const authUser = getUser();

  user.value = {
    auth: authUser.user.value,
    docRef: await projectFirestore.collection("users").doc(authUser.user.value.uid).get(),
  };
  user.value["data"] = user.value["docRef"].data();

  const fbCharities = await projectFirestore.collection("charities").where("posted", "==", true).get();
  fbCharities.forEach((snap) => {
    charities.value[snap.id] = snap.data();
  });
  loading.value = false;
};

onMounted(async () => {
  init();
  resized(window);
  window.addEventListener("resize", (e) => resized(e.target));
});

onUnmounted(() => {
  window.removeEventListener("resize", (e) => resized(e.target));
});

function resized(w) {
  if (w.innerWidth >= 1024) {
    if (numSlides.value != 3.5) numSlides.value = 3.5;
  } else if (w.innerWidth >= 768) {
    if (numSlides.value != 2.5) numSlides.value = 2.5;
  } else if (w.innerWidth > 480) {
    if (numSlides.value != 1.5) numSlides.value = 1.5;
  } else {
    if (numSlides.value != 1) numSlides.value = 1;
  }
}

//Getting amount of totalDonated value in total collection
const total = async () => {
  const docRef = projectFirestore.collection("totals").doc("transactions");
  await docRef.get().then((doc) => {
    totalDonated.value = doc.data().totalDonated;
  });
};

// function to get which organization was clicked on
const handleDonate = async (event, id) => {
  if (user.value["data"]["balance"] <= 0) {
    props.createModal({
      title: "Oops",
      body: `${t("notEnough")}`,
      buttons: [
        {
          text: "Ok",
          action: () => {
            props.createModal({});
          },
        },
      ],
    });
    return;
  }

  console.log();

  props.createModal({
    title: "Confirmation",
    body: `$${user.value["data"].balance ? user.value["data"].balance : 0} ${t("proceed")}`,
    buttons: [
      {
        text: "Confirm",
        action: () => {
          props.createModal({});
          confirmDonate(charities.value["D" + id]);
        },
      },
      {
        text: "Cancel",
        action: () => {
          props.createModal({});
        },
      },
    ],
  });
};

//Handle donateRequest
const confirmDonate = async (charity) => {
  emit("balance");

  try {
    let transactionID = await _fb.addUserTransaction(user.value["data"]["uid"], {
      timeRequested: timestamp(),
      result: "donate",
      charity: charity["charityID"],
      amount: user.value["data"]["balance"],
      active: user.value["data"]["totalConfirmed"],
      status: "Requested",
    });

    await _fb.addPaymentRequest({
      uid: user.value["data"]["uid"],
      amount: user.value["data"]["balance"],
      charity: charity["charityID"],
      status: "donated",
      timeRequested: timestamp(),
      docRef: transactionID,
    });

    await _fb.updateOrgTotalDonated(user.value["data"]["balance"]);
    await _fb.updateCharityTotalDonated(charity["charityID"], user.value["data"]["balance"]);

    await _fb.addUserDonation(user.value["data"]["uid"], charity["charityID"], user.value["data"]["balance"]);
    await _fb.updateUser(user.value["data"]["uid"], {
      balance: 0,
      totalConfirmed: 0,
    });

    emit("balance");
    init();

    props.createModal({
      title: t("success"),
      body: "Your donation has been submited, thank-you for your contribution",
      buttons: [
        {
          text: t("close"),
          action: () => props.createModal({}),
        },
      ],
    });
  } catch (err) {
    props.createModal({
      title: t("failure"),
      body: "Something went wrong while submitting your donation, ",
      buttons: [
        {
          text: t("close"),
          action: () => props.createModal({}),
        },
      ],
    });
    logError(`DonateRequest.confirmDonate: Failed to complete donation. ${user.value["data"]["uid"]}. ${err.toString()}`);
  }
};
</script>

<style lang="scss" scoped>
.charity-carousel {
  padding: 15px;
  margin-bottom: 50px;
  max-height: 90vh;

  .charity-slide {
    padding: 15px;

    .card {
      min-width: 220px;
      display: flex;
      flex-direction: column;
      height: 100%;
      -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.38) !important;
      box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.38) !important;

      .card-charity-image {
        width: 100%;
        height: 35%;
        max-height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .charity-image {
          max-height: 100%;
        }
      }

      .card-content {
        flex: auto;
        height: 50%;
        overflow: auto;
      }

      .card-footer {
        padding-top: 14px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15%;

        button {
          max-width: 100%;
          margin: 0 10px !important;
          word-wrap: break-word;
          white-space: normal !important;
          height: fit-content !important;
        }
      }
    }
  }
}

.header-row {
  width: 100%;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
