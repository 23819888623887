<template>
    <div class="content">
      <Background>
        <FriendlierCard>
          <div class="padded-block">
            <div class="logo"><img :src="FriendlierPrimaryCloud" /></div>
            <Loading v-if="isLoading" isCenter isFull />
            <div v-if="!isLoading" class="text-content">
              <div v-if="validity === true">
                <UpdateEmailForm
                  v-if="!didFinish"
                  title="Update Email"
                  :submit="updateEmail"
                  buttonText="Finish"
                />
                <div v-if="didFinish" class="message">
                  <p>Your email has been updated. An email verification has been sent to you.</p>
                  <div class="sunflower-button" @click="login">Login</div>
                </div>
              </div>
              <div v-else>
                <span class="message">
                  <p v-if="hasParams">Your link has expired, please go to change email on your account to receive a new link</p>
                  <p v-else>Please go to change email on your account to receive a new link</p>
                </span>
              </div>
            </div>
          </div>
        </FriendlierCard>
      </Background>
    </div>
  </template>
  
  <script>
  import { onMounted, ref, watch } from "vue";
  import { useRouter } from "vue-router";
  
  import { Loading, Background, FriendlierCard, RegisterForm, UpdateEmailForm } from "@/revamp/components";
  import { getQueryParams, emailValidator } from "@/revamp/utils";
  import { FriendlierPrimaryOcean, FriendlierPrimaryCloud } from "@/revamp/assets/images";;
  import { request } from "@/revamp/utils";

  export default {
    name: "UpdateEmail",
    components: {
      Loading,
      Background,
      FriendlierCard,
      RegisterForm,
      UpdateEmailForm,
    },
    setup() {
      const hasParams = ref(false);
      const isUpdated = ref(false);
      const didFinish = ref(false);
      const validity = ref(false);
      const isLoading = ref(true);
      const email = ref("");
      const newEmail = ref("");
      const userToken = ref("");
      const emailError = ref("");
      const error = ref("");
  
      const Router = useRouter();
  
      watch(email, () => {
        error.value = "";
        emailError.value = "";
        if (email.value === "") {
          emailError.value = "";
          return;
        }
        if (!emailValidator(email.value)) {
          emailError.value = "Please enter a valid email";
        }
      });
  
      const init = async () => {
        let params = await getQueryParams();

        if (Object.keys(params).length === 0) {
          hasParams.value = false;
          validity.value = false;
          isLoading.value = false;
          return;
        }
  
        hasParams.value = true;
  
        let updateEmailToken = params["user"];
        userToken.value = params["user"];

        if (!("user" in params) || !updateEmailToken) {
          Router.push("FourOhFour");
          return;
        }

        try {
          let response = await request(
            `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/linkValidation`,
            "POST",
            {
              link: updateEmailToken,
              type: "emailUpdateLink",
            },
            "checkUpdateEmailToken",
            false
          );

          if (response.status === "success") {
            validity.value = true;
          } else {
            error.value = response.message;
            validity.value = false;
          }
        } catch (err) {
          error.value = err.message;
          validity.value = false;
        }
  
        isLoading.value = false;
      };
  
      const login = () => {
        Router.push("/");
      };
      
      const updateEmail = async ({email, newEmail}) => {
        try {
          isLoading.value = true;
          let response = await request(
            `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/updateEmail`,
            "POST",
            {
              email: email,
              newEmail: newEmail,
              uniqueId: userToken.value,
            },
            "sendEmailResetEmail",
            false
          );
          if (response.status === "success") {
            didFinish.value = true;
            isUpdated.value = true;
          } else {
            error.value = response.message;
          }
          isLoading.value = false;
        } catch (err) {
          error.value = err.message;
          isLoading.value = false;
        }
      };
  
      onMounted(() => {
        init();
      });
  
      return {
        // Methods
        updateEmail,
        login,
        // Data
        isLoading,
        validity,
        hasParams,
        error,
        email,
        emailError,
        didFinish,
        // Images
        FriendlierPrimaryOcean,
        FriendlierPrimaryCloud,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/revamp/assets/scss/colors.scss";
  @import "@/revamp/assets/scss/elements.scss";
  
  .logo {
    max-width: 30%;
    min-width: 300px;
  }
  
  span.message {
    p {
      color: $cloud;
      margin: 10px;
    }
  }
  .message {
    background-color: #1e3a5f; // Dark blue background
    color: #ffffff; // White text
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px 0;  
  }
  </style>
  