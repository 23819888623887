<template>
  <div class="language-box">
    <select class="select" v-model="$i18n.locale">
      <option value="en">English</option>
      <option value="fr">French</option>
    </select>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";

.language-box {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: $denim;
  width: 125px;
  border-radius: 15px;
  padding: 5px;
}
.select {
  outline: none;
  border: none;

  &:focus {
    outline: none;
  }

  background-color: transparent;
  color: $cloud;
  font-size: 1.25rem !important;
  padding: 7px 12px;
  width: 100%;
}
</style>
