<template>
  <FriendlierCard>
    <div class="padded-block">
      <div class="logo"><img :src="FriendlierPrimaryOcean" /></div>
      <h2 class="is-size-2 mb-4 mt-2 title">Forgot Password?</h2>
      <p class="is-cloud">Enter your email to reset password:</p>
      <form>
        <div class="friendlier-field">
          <p class="control">
            <span class="icon is-medium is-left mr-3">
              <i class="fas fa-envelope"></i>
            </span>
            <input type="email" required placeholder="email" v-model="email" class="is-size-4 is-right p-2" />
          </p>
        </div>
        <button @click.prevent="submit" class="ocean-button mt-3 is-bold fs-1-2">Send</button>
        <p class="mt-2 is-sunflower" :class="{ alert: message.type == 'alert' }">
          {{ message.text }}
        </p>
      </form>
      <div @click="back" class="back m-5 is-sunflower">Back</div>
    </div>
  </FriendlierCard>
</template>
<script>
import { ref } from "vue";
import { FriendlierCard } from "@/revamp/components";
import { FriendlierPrimaryOcean } from "@/revamp/assets/images";
import { AuthService } from "@/revamp/firebase/config";

export default {
  name: "ForgotPassword",
  props: {
    back: Function,
  },
  components: {
    FriendlierCard,
  },
  setup() {
    const message = ref({ type: null, text: "" });
    const email = ref("");

    async function submit() {
      try {
        if (email.value == "") {
          message.value = { type: "alert", text: "Please enter an email" };
          return;
        }
        await AuthService.sendPasswordResetEmail(email.value.trim());
      } catch (err) {
        console.log(`Failed ${err.toString()}`);
        // error log
      }
      email.value = "";
      message.value = {
        type: "msg",
        text: "If this email has an account, check the inbox for a reset password link",
      };
    }

    return {
      // Methods
      submit,
      // Data
      message,
      email,
      // Images
      FriendlierPrimaryOcean,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.padded-block {
  padding: 20px;
  width: 100%;
  text-align: center;
  position: relative;
  padding-bottom: 50px;
}

.title {
  color: $sunflower;
}

.logo {
  max-width: 30%;
  min-width: 300px;
  margin: 0 auto;
}

.alert {
  color: $sunrise;
}

.back {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: $denim;
}
</style>
