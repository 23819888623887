<template>
  <h2 class="is-size-2 mb-4 mt-2 title">{{ title }}</h2>
  <form class="container m-0">
    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-signature"></i>
        </span>
        <input
          type="text"
          required
          :placeholder="$t('registerForm.firstName')"
          v-model="fname"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div id="dummy-spacer" class="mt-4 has-text-danger error"></div>
    </div>

    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-signature"></i>
        </span>
        <input
          type="text"
          required
          :placeholder="$t('registerForm.lastName')"
          v-model="lname"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div id="dummy-spacer" class="mt-4 has-text-danger error"></div>
    </div>

    <div v-if="showEmail" class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-envelope"></i>
        </span>
        <input
          type="email"
          required
          :placeholder="$t('registerForm.email')"
          v-model="email"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div class="mt-4 has-text-danger error">{{ emailError }}</div>
    </div>

    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-key"></i>
        </span>
        <input
          type="password"
          required
          :placeholder="$t('registerForm.password')"
          v-model="password"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div class="mt-4 has-text-danger error">{{ passwordError }}</div>
    </div>

    <div class="friendlier-field mt-2">
      <p class="control">
        <span class="icon is-medium is-left mr-3">
          <i class="fas fa-key"></i>
        </span>
        <input
          type="password"
          required
          :placeholder="$t('registerForm.confirm-password')"
          v-model="confPassword"
          class="is-size-4 is-right p-2"
        />
      </p>
      <div class="mt-4 has-text-danger error">{{ confPasswordError }}</div>
    </div>
  </form>
  <button
    :onclick="validate"
    :disabled="locked"
    class="ocean-button is-bold fs-1-2"
  >
    {{ buttonText }}
  </button>
  <div class="mt-4 has-text-danger error">{{ error }}</div>
</template>
<script>
import { ref, watch, computed } from "vue";
import {
  emailValidator,
  passwordMatch,
  passwordValidator,
} from "@/revamp/utils";
import { logError } from "@/revamp/utils";
import { useI18n } from "vue-i18n";

export default {
  props: {
    showEmail: Boolean,
    locked: Boolean,
    title: String,
    buttonText: String,
    emailVal: String,
    submit: Function,
  },
  setup(props) {
    const email = ref(props.emailVal ? props.emailVal : "");
    const emailError = ref("");
    const fname = ref("");
    const lname = ref("");
    const password = ref("");
    const passwordError = ref("");
    const confPassword = ref("");
    const confPasswordError = ref("");
    const error = ref("");

    const { t } = useI18n({
      locale: "en",
      messages: {
        en: {
          validEmail: "Please enter a valid email",
          allFields: "Please complete all fields",
          fix: "Fix problems before submitting",
        },
        fr: {
          validEmail: "Veuillez saisir un courriel valide",
          allFields: "Veuillez remplir tous les champs",
          fix: "Réglez les problèmes avant de soumettre",
        },
      },
    });

    const msg = computed(() => t("validEmail", "allFields", "fix"));

    watch(email, () => {
      error.value = "";
      emailError.value = "";
      if (email.value == "") {
        emailError.value = "";
        return;
      }
      if (!emailValidator(email.value)) {
        // emailError.value = t("validEmail");
        emailError.value = t("validEmail");
      }
    });

    watch(password, () => {
      error.value = "";
      passwordError.value = passwordValidator(password.value);
      confPasswordError.value = passwordMatch(
        password.value,
        confPassword.value
      );
    });

    watch(confPassword, () => {
      error.value = "";
      confPasswordError.value = passwordMatch(
        password.value,
        confPassword.value
      );
    });

    const validate = async () => {
      error.value = "";
      if (
        fname.value == "" ||
        lname.value == "" ||
        password.value == "" ||
        confPassword.value == ""
      ) {
        error.value = t("allFields");
        return;
      }
      if (props.showEmail && email.value == "") {
        error.value = t("allFields");
        return;
      }

      if (
        emailError.value != "" ||
        passwordError.value != "" ||
        confPasswordError.value != ""
      ) {
        error.value = t("fix");
        return;
      }

      props.submit({
        fname: fname.value,
        lname: lname.value,
        password: password.value,
        ...(props.showEmail ? { userEmail: email.value } : {}),
      });
    };

    return {
      // Data
      fname,
      lname,
      password,
      passwordError,
      confPassword,
      confPasswordError,
      error,
      email,
      emailError,
      // Methods
      validate,

      t,
      msg,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.title {
  color: $sunflower;
}

span.icon {
  width: 20px;
}
</style>
