<template>
  <div class="content" id="dynamic" :style="{ 'overflow-y': 'scroll' }">
    <div class="is-flex is-justify-content-center is-align-items-flex-start is-flex-direction-column">
      <div class="is-full-width is-back-denim is-cloud">
        <div class="container-base">
          <p class="is-ocean m-0 p-0">+1 Life</p>
          <div class="image-container">
            <div class="image" :style="{ backgroundImage: 'url(' + ContainerBase + ')' }" />
          </div>
        </div>
        <div class="counter-container">
          <p>{{ containersScanned }}</p>
          <div>
            containers returned<br />
            at the RC show so far
          </div>
        </div>
        <div class="bin-container">
          <div>
            <div class="image-container">
              <div class="image" :style="{ backgroundImage: 'url(' + Bin_1 + ')' }" />
            </div>
            <p>return bins are located at the center aisle</p>
          </div>
        </div>

        <div class="drawer-container" :class="{ active: drawerOneOpen }">
          <div class="upper" />
          <div class="lower" />
          <div
            class="drawer-main"
            :style="{ backgroundImage: 'url(' + BlueBackground + ')' }"
            @click="() => (drawerOneOpen = !drawerOneOpen)"
          >
            <p class="drawer-title">what we do</p>
            <div class="drawer-image">
              <div class="image" :style="{ backgroundImage: 'url(' + OfferFood + ')' }" />
            </div>
            <div class="drawer-open">
              <div
                class="image"
                :class="{ flipped: drawerOneOpen }"
                :style="{
                  backgroundImage: 'url(' + (!drawerOneOpen ? DownChevron : UpChevron) + ')',
                }"
              />
            </div>
          </div>
          <div class="drawer-slide" :class="{ active: drawerOneOpen }">
            <p>
              We work with a variety of food establishments to eliminate single-use packaging. Our reusable container service
              makes it super easy to be sustainable.
            </p>
            <a class="button" href="https://www.friendlier.ca/for-businesses" target="_blank" referrer="no-referrer"
              >learn more</a
            >
          </div>
        </div>

        <div class="drawer-container" :class="{ active: drawerTwoOpen }">
          <div class="upper" :style="{ background: '#f0ba30' }" />
          <div class="lower" />
          <div
            class="drawer-main"
            :style="{ backgroundImage: 'url(' + YellowBackground + ')' }"
            @click="() => (drawerTwoOpen = !drawerTwoOpen)"
          >
            <p class="drawer-title">contact us</p>
            <div class="drawer-column">
              <div class="drawer-image">
                <div class="image" :style="{ backgroundImage: 'url(' + ContactUs + ')' }" />
              </div>
              <div class="drawer-buttons">
                <a
                  href="mailto:info@friendlier.ca"
                  target="_blank"
                  referrer="no-referrer"
                  :style="{ backgroundColor: '#ffe49e' }"
                >
                  email us
                </a>
                <a
                  href="https://meetings.hubspot.com/rick-kowch/afc-intro-meeting"
                  target="_blank"
                  referrer="no-referrer"
                  :style="{ border: '2px solid #f0ba30' }"
                >
                  book a call
                </a>
              </div>
            </div>
            <div class="drawer-open">
              <div
                class="image"
                :style="{
                  backgroundImage: 'url(' + (!drawerTwoOpen ? DownChevron : UpChevron) + ')',
                }"
              />
            </div>
          </div>
          <div class="drawer-slide" :class="{ active: drawerTwoOpen }">
            <p>
              Let's talk about serving reusables! Shoot us an email or schedule a video call with our friendly sales rep, Rick,
              to get started.
            </p>
            <p>info@friendlier.ca</p>
          </div>
        </div>

        <div class="drawer-container" :class="{ active: drawerThreeOpen }">
          <div class="upper" :style="{ background: '#fa7854' }" />
          <div class="lower" />
          <div
            class="drawer-main"
            :style="{ backgroundImage: 'url(' + PinkBackground + ')' }"
            @click="() => (drawerThreeOpen = !drawerThreeOpen)"
          >
            <p class="drawer-title">download our app</p>
            <div class="drawer-column">
              <div class="drawer-image">
                <div class="image" :style="{ backgroundImage: 'url(' + Phone + ')' }" />
              </div>
              <div class="app-badges">
                <a
                  :href="
                    isMobile()
                      ? 'https://afcbeta.page.link/download'
                      : 'https://play.google.com/store/apps/details?id=com.afriendliercompany.afcbeta'
                  "
                  target="_blank"
                  ref="no-referrer"
                >
                  <img :src="GooglePlayBadge_EN" alt="Download on Google Play" />
                </a>
                <a
                  :href="
                    isMobile() ? 'https://afcbeta.page.link/download' : 'https://apps.apple.com/ca/app/friendlier/id1533593665'
                  "
                  target="_blank"
                  ref="no-referrer"
                >
                  <img :src="AppStoreBadge_EN" alt="Download on the Apple App Store"
                /></a>
              </div>
            </div>
            <div class="drawer-open">
              <div
                class="image"
                :style="{
                  backgroundImage: 'url(' + (!drawerThreeOpen ? DownChevron : UpChevron) + ')',
                }"
              />
            </div>
          </div>
          <div class="drawer-slide" :class="{ active: drawerThreeOpen }">
            <p>
              Did you know when you scan and return your container, you can collect your money back? Get our mobile app to start
              reusing and track your impact!
            </p>
          </div>
        </div>

        <div
          class="drawer-container"
          :style="{
            background: '#021641',
            padding: '20px',
          }"
        >
          <div class="drawer-main">
            <p
              class="drawer-title"
              :style="{
                color: '#ededed',
              }"
            >
              follow us
            </p>
            <div class="icons-container">
              <p>@be.friendlier</p>
              <div class="icon-tray">
                <a class="icon-container" href="https://www.facebook.com/be.friendlier/" target="_blank" referrer="no-referrer">
                  <div class="image" :style="{ backgroundImage: 'url(' + FacebookIcon + ')' }" />
                </a>
                <a
                  class="icon-container"
                  href="https://www.instagram.com/be.friendlier/"
                  target="_blank"
                  referrer="no-referrer"
                >
                  <div class="image" :style="{ backgroundImage: 'url(' + InstagramIcon + ')' }" />
                </a>
                <a
                  class="icon-container"
                  href="https://www.linkedin.com/company/friendlier/"
                  target="_blank"
                  referrer="no-referrer"
                >
                  <div class="image" :style="{ backgroundImage: 'url(' + LinkedinIcon + ')' }" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer" :class="{ active: drawerOneOpen || drawerTwoOpen || drawerThreeOpen || drawerFourOpen }" />
      </div>
    </div>
    <div class="footer-container">
      <div class="container">
        <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
          <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: none"></path>
        </svg>
      </div>
      <div class="icons">
        <div><div class="image" :style="{ backgroundImage: 'url(' + FriendlierPrimaryOcean + ')' }" /></div>
        <div><div class="image" :style="{ backgroundImage: 'url(' + RCShowLogo + ')' }" /></div>
      </div>
      <div class="location">
        <div class="image-container"><div class="image" :style="{ backgroundImage: 'url(' + LocationProfile + ')' }" /></div>
        <div class="text">booth 608, Eco Pavilion</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { LeftChevron, DownChevron, UpChevron, FriendlierPrimaryOcean } from "@/revamp/assets/images";
import {
  Ecobee,
  SmartSensor,
  SmartSensorForDoors,
  SmartThermostat,
  ContainerBase,
  Bin_1,
  RCShowLogo,
  LocationProfile,
  BlueBackground,
  YellowBackground,
  PinkBackground,
  ContactUs,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  OfferFood,
  Phone,
} from "@/assets/RCShow";
import { GooglePlayBadge_FR, GooglePlayBadge_EN, AppStoreBadge_EN, AppStoreBadge_FR } from "@/assets";

import QRious from "qrious";
import { useFirebase } from "@/revamp/composables";
import { DatabaseService } from "@/revamp/firebase/config";

const containersScanned = ref(0);

const drawerOneOpen = ref(false);
const drawerTwoOpen = ref(false);
const drawerThreeOpen = ref(false);
const drawerFourOpen = ref(false);
const _fb = useFirebase();

const appDownload = new QRious({
  size: 200,
  value: "https://afcbeta.page.link/download",
  level: "H",
});
const appDownloadURL = ref(appDownload.toDataURL());

const isMobile = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

onMounted(async () => {
  let response = await _fb.getRCReturnCount();
  containersScanned.value = response["message"];
});

const drawers = ref([
  {
    id: 0,
    title: "hello",
    src: OfferFood,
    background: BlueBackground,
    body: "Hello There ding dong",
    extra: "button",
  },
  {
    id: 0,
    title: "hello",
    src: OfferFood,
    background: YellowBackground,
    body: "Hello There ding dong",
    extra: "button",
  },
  {
    id: 0,
    title: "hello",
    src: OfferFood,
    background: PinkBackground,
    body: "Hello There ding dong",
  },
  {
    id: 0,
    title: "hello",
    src: OfferFood,
    background: BlueBackground,
    body: "Hello There ding dong",
  },
]);
</script>
<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";
@import "@/revamp/assets/scss/variables.scss";

.container-base {
  margin: 40px auto 0 auto;
  width: 200px;
  font-size: 2rem;

  p {
    display: inline-block;
    position: absolute;
  }
  .image-container {
    height: 200px;
    width: 200px;

    .image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      height: 100%;
      width: 100%;
    }
  }
}

.counter-container {
  color: $cloud;
  width: 100%;
  text-align: center;

  p {
    font-size: 4rem;
    margin: 0 !important;
    padding: 0;
    font-weight: bold;
  }

  div {
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.bin-container {
  margin: 40px;

  > div {
    border: 1px solid $ocean;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .image-container {
      height: 65px;
      width: 50px;
      margin-right: 20px;

      .image {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        height: 100%;
        width: 100%;
      }
    }
    p {
      text-align: center !important;
    }
  }
}

.footer-container {
  padding-bottom: 40px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: $cloud;

  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    fill: $denim;
  }
  .container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100px;
    margin-top: -1px;
    overflow: hidden;
  }

  .icons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    > div {
      height: 75px;
      width: 150px;
      .image {
        height: 100%;
        width: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .location {
    display: flex;
    align-items: center;
    width: 70%;
    height: 75px;
    position: relative;
    margin: 0 auto;

    .image-container {
      position: absolute;
      left: -25px;

      height: 75px;
      width: 75px;
      .image {
        height: 100%;
        width: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .text {
      background-color: rgba($red-highlight, 0.3);
      padding: 1px 12px;
      padding-left: 10px;
      color: $denim;
      width: 100%;
      font-size: 1.2rem;
      font-weight: bold;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      line-height: 0;
      height: 40px;
      right: 60px;
    }
  }
}

.drawer-container {
  height: 250px;
  width: 85%;
  margin: 40px auto;
  border-radius: 15px;
  position: relative;
  transition: 0.5s margin ease-in-out;

  &.active {
    margin-bottom: 290px;
  }

  .upper {
    background-color: $ocean;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    height: 50px;
  }

  .lower {
    background-color: $cloud;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    height: 50px;
  }

  .drawer-main {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    padding: 20px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    .drawer-title {
      font-size: 1.5rem;
      color: $denim;
      font-weight: bold;
    }

    .drawer-column {
      display: flex;
      justify-content: space-between;
      height: 130px;

      > .drawer-image {
        height: 100% !important;
      }
    }

    .drawer-image {
      height: 60%;
      width: 50%;
      .image {
        height: 100%;
        width: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .drawer-open {
      position: absolute;
      right: 20px;
      height: 25px;
      width: 25px;

      .image {
        height: 100%;
        width: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .drawer-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 40%;

      a {
        display: inline-block;
        padding: 7px 12px;
        margin: 5px;
        font-weight: bold;
        border-radius: 10px;
      }
    }

    .icons-container {
      width: 50%;
      position: absolute;
      bottom: 20px;
      right: 60px;

      p {
        font-weight: bold;
        font-size: 2rem;
        margin: 0;
        padding: 0;
      }

      .icon-tray {
        display: flex;
        justify-content: space-between;
        .icon-container {
          height: 50px;
          width: 50px;
          .image {
            height: 100%;
            width: 100%;

            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  .drawer-slide {
    height: 250px;
    margin: 0 auto;
    border-radius: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    margin-top: 0;
    transition: 0.5s all ease-in-out;

    background-color: darken($denim, 10%);
    padding: 30px;

    &.active {
      top: 250px;
    }

    p {
      font-size: 1.2rem;
      word-wrap: break-word;
    }

    .button {
      background-color: $ocean;
      border: 1px solid $denim;
      color: $denim;

      padding: 7px 12px;
      font-weight: bold;
    }
  }
}

.spacer {
  height: 310px;
  transition: 0.5s all ease-in-out;

  &.active {
    height: 560px;
  }
}

.app-badges {
  display: flex;
  flex-direction: column;
  width: 50%;

  a {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
