<template>
  <div class="background" :style="{ backgroundImage: 'url(' + OceanBackDenimCirclesSparse + ')' }">
    <slot />
  </div>
</template>
<script>
import { OceanBackDenimCirclesSparse } from "@/revamp/assets/backgrounds";

export default {
  setup() {
    return {
      OceanBackDenimCirclesSparse,
    };
  },
};
</script>
<style lang="scss" scoped>
.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  overflow: auto;
}
</style>
