<template>
  <div class="page">
    <Loading v-if="loading" isFull isCenter isTall />
    <!-- <template > -->
    <!-- <AdminHome v-if="userData.admin" /> -->
    <CustomerHome v-else />
    <!-- </template> -->
  </div>
</template>

<script setup>
import { onMounted, watch, ref } from "vue";
import { useRouter } from "vue-router";

import getUser from "@/composables/getUser";
import { CustomerHome } from "@/components";
// import { AdminHome } from "@/components/admin";

import { Loading } from "@/revamp/components";
import { useFirebase } from "@/revamp/composables";

const router = useRouter();
const _fb = useFirebase();
const { user } = getUser();
const userData = ref();

const loading = ref(true);

onMounted(async () => {
  init();
});

async function init() {
  if (user.value == null || user.value == undefined) return;
  userData.value = await _fb.getUserDetails(user.value.uid);
  loading.value = false;
}

watch(
  user,
  () => {
    if (!user.value) {
      router.push({ name: "Welcome" });
    }
  },
  { immidiate: true }
);
</script>

<style>
.page {
  height: 100vh;
  width: 100vw;
  position: relative;
}
</style>
