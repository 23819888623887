<template>
  <div class="content">
    <Background>
      <FriendlierCard>
        <div class="padded-block">
          <div class="logo"><img :src="FriendlierPrimaryCloud" /></div>
          <Loading v-if="isLoading" isCenter isFull />
          <div v-if="!isLoading" class="text-content">
            <div v-if="validity == null"></div>
            <div v-if="validity == true">
              <span v-if="didFinish" class="message"
                ><p>
                  Your profile is complete! Please login to manage your returns
                  and track your impact.
                </p>
                <div class="sunflower-button" @click="login">Login</div></span
              >
              <span v-else>
                <RegisterForm
                  title="Complete Profile"
                  :submit="signup"
                  buttonText="Finish"
                />
              </span>
            </div>

            <div v-if="validity == false">
              <div v-if="!isSent">
                <span class="message">
                  <p v-if="hasParams">
                    Your link has expired, please re-enter your email to send
                    another link
                  </p>
                  <p v-else>
                    Please enter your email complete your signup. We will send
                    you a link to confirm access to your email
                  </p>
                </span>
                <div class="friendlier-field">
                  <p class="control">
                    <span class="icon is-medium is-left mr-3">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      required
                      placeholder="email"
                      v-model="email"
                      class="is-size-4 is-right p-2"
                    />
                  </p>
                </div>
                <div class="sunflower-button is-bold" @click="sendSignupEmail">
                  Send
                </div>
                <div class="mt-4 has-text-danger error">{{ emailError }}</div>
              </div>
              <div v-if="isSent">
                <span class="message"
                  ><p>
                    Thank-you! Check your inbox for an email in a few moments,
                    make sure to check your junkmail as well.
                  </p></span
                >
              </div>
            </div>
            <div class="mt-4 has-text-danger error">{{ error }}</div>
          </div>
        </div>
      </FriendlierCard>
    </Background>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useFirebase } from "@/revamp/composables";
import {
  Loading,
  RegisterForm,
  Background,
  FriendlierCard,
} from "@/revamp/components";
import { getQueryParams, emailValidator, logError } from "@/revamp/utils";
import { FriendlierPrimaryOcean } from "@/revamp/assets/images";
import { FriendlierPrimaryCloud } from "@/revamp/assets/images";

import { notifyStore } from "@/revamp/store";

export default {
  name: "Complete Signup",
  components: {
    Loading,
    RegisterForm,
    Background,
    FriendlierCard,
  },
  setup() {
    const hasParams = ref(false);
    const isSent = ref(false);
    const didFinish = ref(false);
    const validity = ref();
    const isLoading = ref(true);
    const email = ref("");
    const emailError = ref("");
    const error = ref("");
    const willVerify = ref(false);

    const APIService = useFirebase();
    const Router = useRouter();

    watch(email, () => {
      error.value = "";
      emailError.value = "";
      if (email.value == "") {
        emailError.value = "";
        return;
      }
      if (!emailValidator(email.value)) {
        emailError.value = "Please enter a valid email";
      }
    });

    const init = async () => {
      // Check for Params
      let params = await getQueryParams();

      // If no params, show generic email entry to re-trigger signup flow
      if (Object.keys(params).length == 0) {
        hasParams.value = false;
        validity.value = false;
        isLoading.value = false;
        return;
      }

      hasParams.value = true;

      // If params, grab, validate,
      let signUpLink = params["user"];
      if (
        !("user" in params) ||
        params["user"] == null ||
        params["user"] == undefined
      ) {
        Router.push("FourOhFour");
      }
      if (
        "verify" in params &&
        params["verify"] != null &&
        params["verify"] != undefined
      ) {
        willVerify.value = params["verify"];
      }

      let response = await APIService.checkSignUpLink(signUpLink);
      if (response.error) {
        switch (response.code) {
          case 10000:
            error.value = "Failed to complete. Missing fields";
            logError(
              "CompleteSignup.signup: failed to complete signup, missing fields"
            );
            break;
          case 20000:
          case 20001:
            Router.push("FourOhFour");
            break;
          default:
            unknownError();
            break;
        }
      }
      validity.value = response.isValid;
      if (response.isValid) {
        email.value = response.email;
      }

      // if invalid, show expired email entry to re-trigger signup flow
      // if valid, show signup fields
      isLoading.value = false;
    };

    const unknownError = async () => {
      notifyStore.createModal(
        "Error",
        "Something has gone wrong, please try again later or reach out to Friendlier for help",
        [
          {
            id: "close",
            text: "Dismiss",
            action: "close",
          },
        ],
        true
      );
    };

    const signup = async ({ fname, lname, password, userEmail }) => {
      if (userEmail != null) {
        email.value = userEmail.toLowerCase().trim();
      }
      try {
        isLoading.value = true;
        let response = await APIService.finishSignup(
          email.value,
          password,
          fname,
          lname,
          willVerify.value
        );
        if (response.error) {
          switch (response.code) {
            case 10000:
              error;
              break;
            default:
              unknownError();
              break;
          }
        }
        didFinish.value = response.didFinish;
      } catch (e) {
        logError(
          `CompleteSignup.Signup: failed to update user info. ${e.toString()}`
        );
      }
      isLoading.value = false;
    };

    const login = () => {
      Router.push("/");
    };

    const sendSignupEmail = async () => {
      try {
        isLoading.value = true;
        let response = await APIService.sendSignupEmail(email.value);
        if (response.isSent) {
          isSent.value = true;
        } else {
          emailError.value =
            "We're sorry, something has gone wrong. Please try again later";
        }
      } catch (e) {
        logError(
          `CompleteSignup.SendSignupEmail: Failed to send email ${e.toString()}`
        );
      }
      isLoading.value = false;
    };

    onMounted(async () => {
      init();
    });

    return {
      // Methods
      signup,
      sendSignupEmail,
      login,
      // Data
      isLoading,
      validity,
      isSent,
      hasParams,
      error,
      email,
      emailError,
      didFinish,
      // Images
      FriendlierPrimaryOcean,
      FriendlierPrimaryCloud,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";

.logo {
  max-width: 30%;
  min-width: 300px;
}

span.message {
  p {
    color: $cloud;
    margin: 10px;
  }
}
</style>
