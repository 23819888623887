import {
  DatabaseService,
  increment,
  timestamp,
  FIREBASE_FUNCTION_HEADERS,
} from "@/revamp/firebase/config";
import { logError } from "@/revamp/utils";

const CODE_COLLECTION = "QRCodes";

async function verifyCode(code) {
  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/VerifyCode`,
      {
        method: "POST",
        headers: FIREBASE_FUNCTION_HEADERS,
        body: JSON.stringify({ code: code }),
      }
    );
    return await response.json();
  } catch (e) {
    logError(`QRCodeFunctions.verifyCode: Failed to verify code ${code}`);
    return null;
  }
}

async function onBorrowVerify(codeID) {
  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/onScanBorrow`,
      {
        method: "POST",
        headers: FIREBASE_FUNCTION_HEADERS,
        body: JSON.stringify({
          id: codeID,
        }),
      }
    );
    return await response.json();
  } catch (e) {
    logError(
      `OutletFunctions.outletQuickClaim Failed to claim code ${codeID} ${e.toString()}`
    );
    return null;
  }
}

async function getQRCode(codeID) {
  if (!codeID) {
    return null;
  }

  let url_query = await DatabaseService.collection(CODE_COLLECTION)
    // .where("newUrl", "==", `https://friendlier-mobile-dev.web.app/${codeID}`)
    .where("newUrl", "==", `https://links.friendlier.com/${codeID}`)
    .get();

  if (url_query.empty) {
    return null;
  }

  // Only need the string of the document
  let displayCode = url_query.docs[0].data().displayCode;

  return displayCode;
}

async function claimCode(code, email, scanalytics, claimType = null) {
  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/ClaimCode`,
      {
        method: "POST",
        headers: FIREBASE_FUNCTION_HEADERS,
        body: JSON.stringify({
          code: code,
          email: email,
          scanalytics: scanalytics,
          claimType: claimType,
        }),
      }
    );
    return await response.json();
  } catch (e) {
    logError(`QRCodeFunctions.ClaimCode Failed to claim code ${code}`);
    return null;
  }
}

async function claimBorrowedCodes(codes, userURL) {
  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/borrowClaimCode`,
      {
        method: "POST",
        headers: FIREBASE_FUNCTION_HEADERS,
        body: JSON.stringify({
          codes: codes,
          userURL: userURL,
        }),
      }
    );
    return await response.json();
  } catch (e) {
    logError(
      `QRCodeFunctions.claimBorrowedCodes Failed to claim codes ${codes}`
    );
    return null;
  }
}

const qrCodes_functions = {
  verifyCode,
  claimCode,
  getQRCode,
  onBorrowVerify,
  claimBorrowedCodes,
};

export default qrCodes_functions;
