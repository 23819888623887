import { Store } from "@/revamp/store/store";
import clone from "just-clone";

class NotifyStore extends Store {
  data() {
    return {
      messageQueue: [],
    };
  }

  async log(message, boxId, contId, resId) {
    //this function requires an error msg
    if (!message) {
      message = "Log failed. Please submit a msg when you call log()";
      contId = "";
      boxId = "";
      resId = "";
      this.createToast(message, 2000, false);
      return;
    }

    // const details = await getDetails();
    // let docName = "";

    //the error document will be named based on what item (box or container) is throwing the error
    // if (contId) docName = `${contId} ${details.dateTime.format()}`;
    // else if (boxId) docName = `${boxId} ${details.dateTime.format()}`;
    // else docName = `Backend Error ${details.dateTime.format()}`;

    //fill the empty parameter with a more readable "empty" value
    if (!boxId) boxId = "N/A";
    if (!resId) resId = "N/A";
    if (!contId) contId = "N/A";

    //log the error in the errors collection
    // await projectFirestore.collection("errors").doc(docName).set({
    //   boxId: boxId,
    //   contId: contId,
    //   error: errorMsg,
    //   open: true,
    //   resId: resId,
    //   staffName: details.fullName,
    //   timeRecorded: details.dateTime.format(),
    //   timeClosed: null,
    // });

    // let bodyString =
    //   `*Error!*: ${errorMsg} \n *Found by*: ${
    //     details.fullName
    //   } \n *At*: ${details.dateTime.format()} \n *Details*: \n` +
    //   (contId != "N/A" ? `\t *Active Container*: ${contId}\n` : "") +
    //   (boxId != "N/A" ? `\t *Selected Box*: ${boxId}\n` : "") +
    //   (resId != "N/A" ? `\t *Selected Restaurant*: ${resId}\n` : "");

    // await fetch(process.env.VUE_APP_ERROR_SLACK_BOT_URL, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     type: "mrkdwn",
    //     text: bodyString,
    //   }),
    // });

    //log the error in a container's error subcollection
    // if (contId != "N/A")
    //   await projectFirestore.collection("QRCodes").doc(contId).collection("errors").doc(docName).set({
    //     boxId: boxId,
    //     contId: contId,
    //     error: errorMsg,
    //     open: true,
    //     resId: resId,
    //     staffName: details.fullName,
    //     timeRecorded: details.dateTime.format(),
    //     timeClosed: null,
    //   });
  }

  createModal(title, body, options, isCover) {
    this.sendMessage({ target: "modal", title, body, options, isCover });
  }

  createToast(message, timeout, intent) {
    this.sendMessage({ target: "toast", message, timeout, intent });
  }

  sendMessage(message) {
    this.state.messageQueue.push(message);
  }

  readMessage() {
    if (this.state.messageQueue.length == 0) return null;
    return clone(this.state.messageQueue[0]);
  }

  deQueue() {
    this.state.messageQueue.shift();
  }
}

export const notifyStore = new NotifyStore("notify-store");
