<template>
  <div class="full-page">
    <Background>
      <div class="headerTitle">
        <div style="font-size: 25px">Containers Scanned</div>

        <div style="width: 300px">
          <QrcodeStream
            v-if="isScanning"
            :constraints="selectedConstraints"
            @error="onError"
            @detect="onDetect"
            @camera-on="onCameraReady"
          />

          <v-btn
            class="text-none"
            color="indigo-darken-3"
            size="x-large"
            variant="flat"
            style="margin-bottom: 30px"
            @click="toggleCamera"
          >
            {{ isScanning ? "Close Camera" : "Open Camera" }}
          </v-btn>

          <v-text-field
            v-model="scanId"
            label="Enter Container ID or User"
            outlined
            dense
            style="margin-right: 20px"
          ></v-text-field>
          <v-btn
            class="text-none"
            color="indigo-darken-3"
            size="x-large"
            variant="flat"
            :loading="isLoading"
            block
            @click="() => submitScan()"
          >
            Submit
          </v-btn>
        </div>
      </div>

      <v-row style="width: 98%">
        <v-col cols="8">
          <v-table
            style="
              width: 100%;
              border-radius: 10px;
              margin-bottom: 3em;
              padding: 20px 10px 5px 5px;
            "
          >
            <thead>
              <tr>
                <th class="text-left">Container ID</th>
                <th class="text-left">Container Type</th>
                <th class="text-left">Deposit Amount</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in validCodes" :key="item._id">
                <td>{{ item._displayCode }}</td>
                <td>{{ item._type }}</td>
                <td>{{ item._deposit }}</td>
                <td>
                  <v-btn
                    :loading="isLoading"
                    @click="removeItem(item)"
                    style="margin-top: 7px; background-color: red; color: white"
                    >Delete</v-btn
                  >
                </td>
              </tr>
              <tr v-if="validCodes.length === 0">
                <td colspan="3" class="text-center">
                  No data available. Please scan a container.
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col cols="4" style="padding: 12px">
          <div style="background-color: white; border-radius: 10px">
            <div
              style="
                font-size: 18px;
                font-weight: 800;
                padding: 10px;
                padding-bottom: 0px;
              "
            >
              Previous Order
            </div>
            <div style="font-size: 14px; font-weight: 600; padding: 5px 10px">
              User ID: {{ previousUserData.uid }}
            </div>
            <div style="font-size: 14px; font-weight: 600; padding: 5px 10px">
              User Name: {{ previousUserData.fname }}
              {{ previousUserData.lname }}
            </div>
            <v-table
              style="
                width: 100%;
                border-radius: 10px;
                margin-bottom: 3em;
                padding: 10px 10px 5px 5px;
              "
            >
              <thead>
                <tr>
                  <th class="text-left">Container ID</th>
                  <th class="text-left">Container Type</th>
                  <th class="text-left">Deposit Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in previousCodes" :key="item._id">
                  <td>{{ item._displayCode }}</td>
                  <td>{{ item._type }}</td>
                  <td>{{ item._deposit }}</td>
                </tr>
                <tr v-if="previousCodes.length === 0">
                  <td colspan="3" class="text-center">
                    No previous order data available.
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </v-col>
      </v-row>
    </Background>
    <!-- Success Snackbar -->
    <v-snackbar
      v-model="snackbar.success.visible"
      :timeout="3000"
      color="success"
    >
      {{ snackbar.success.message }}
    </v-snackbar>

    <!-- Error Snackbar -->
    <v-snackbar v-model="snackbar.error.visible" :timeout="3000" color="error">
      {{ snackbar.error.message }}
    </v-snackbar>
  </div>
</template>

<script setup>
import { Background } from "@/revamp/components";
import { useFirebase } from "@/revamp/composables";
import { ref, onMounted } from "vue";
import { logError } from "@/revamp/utils";
import { QrcodeStream } from "vue-qrcode-reader";

const APIService = useFirebase();

const scanId = ref("");
const containerData = ref(null);
const isLoading = ref(false);
const validCodes = ref([]); //Used for localStorage
const previousCodes = ref([]); //Used for localStorage too
const previousUserData = ref({}); //Used for localStorage too

const emits = defineEmits(["submit"]);

const isScanning = ref(false);
const error = ref("");

function toggleCamera() {
  isScanning.value = !isScanning.value;
}

const defaultConstraintOptions = [
  { label: "rear camera", constraints: { facingMode: "environment" } },
  { label: "front camera", constraints: { facingMode: "user" } },
];
const constraintOptions = ref(defaultConstraintOptions);

// Snackbar state
const snackbar = ref({
  success: {
    visible: false,
    message: "",
  },
  error: {
    visible: false,
    message: "",
  },
});

const loadPreviousCodes = () => {
  const storedPreviousCodes =
    JSON.parse(localStorage.getItem("previousCodes")) || [];
  previousCodes.value = storedPreviousCodes;
};

const loadValidCodes = () => {
  const storedCodes = JSON.parse(localStorage.getItem("validCodes"));
  if (storedCodes && Array.isArray(storedCodes)) {
    validCodes.value = storedCodes;
  }
};

const loadPreviousUser = () => {
  const storedUser = JSON.parse(localStorage.getItem("previousUser"));
  if (storedUser) {
    previousUserData.value = storedUser;
  }
};

onMounted(() => {
  loadValidCodes();
  loadPreviousCodes();
  loadPreviousUser();
});

const updateLocalStorage = () => {
  localStorage.setItem("validCodes", JSON.stringify(validCodes.value));
};

const removeItem = (item) => {
  validCodes.value = validCodes.value.filter(
    (code) => code._displayCode !== item._displayCode
  );
  updateLocalStorage(); // Update localStorage after removing the item
  snackbar.value.success.message = "Container removed successfully.";
  snackbar.value.success.visible = true;
};

const copyToPreviousCodes = () => {
  const currentValidCodes =
    JSON.parse(localStorage.getItem("validCodes")) || [];
  localStorage.setItem("previousCodes", JSON.stringify(currentValidCodes));
  localStorage.removeItem("validCodes");
};

const submitScan = async (scannedCode = null) => {
  const codeToProcess = scannedCode ? scannedCode : scanId.value.trim();
  if (!codeToProcess) {
    snackbar.value.error.message = "Container ID or QR code cannot be empty.";
    snackbar.value.error.visible = true;
    return;
  }

  console.log(codeToProcess);

  isLoading.value = true; // Start the loader

  try {
    const response = await APIService.onBorrowVerify(codeToProcess);
    console.log(response);

    if (response && response.status === "success" && response.data) {
      if (response.data._userURL) {
        const user = response.data;
        const userURL = user._userURL;

        const previousUser = {
          fname: user._fname,
          lname: user._lname,
          uid: user._uid,
        };
        localStorage.setItem("previousUser", JSON.stringify(previousUser));

        previousUserData.value = previousUser;

        console.log(
          validCodes.value.map((container) => container._displayCode)
        );

        // Call the new function with the list of validCodes and the user's userURL
        const claimResponse = await APIService.claimBorrowedCodes(
          validCodes.value.map((container) => container._displayCode),
          userURL
        );
        console.log(claimResponse);

        // Copy validCodes to previousCodes and clear validCodes
        copyToPreviousCodes();

        previousCodes.value =
          JSON.parse(localStorage.getItem("previousCodes")) || [];

        // Clear the current list and state after processing all claims
        validCodes.value = [];
        containerData.value = null;
        updateLocalStorage();

        snackbar.value.success.message =
          "All containers claimed successfully, starting fresh.";
        snackbar.value.success.visible = true;
      } else if (response.data._isReturned) {
        snackbar.value.error.message =
          "This container has already been returned.";
        snackbar.value.error.visible = true;
      } else {
        const isDuplicate = validCodes.value.some(
          (item) => item._displayCode === response.data._displayCode
        );

        if (!isDuplicate) {
          containerData.value = response.data;
          validCodes.value.push(response.data);
          updateLocalStorage();
          snackbar.value.success.message =
            response.message || "Scan successful!";
          snackbar.value.success.visible = true;
        } else {
          snackbar.value.error.message =
            "This container has already been scanned.";
          snackbar.value.error.visible = true;
        }
      }
    } else {
      snackbar.value.error.message =
        response.message ||
        "Failed to scan the container. Please make sure it is a Friendlier container.";
      snackbar.value.error.visible = true;
    }
  } catch (error) {
    snackbar.value.error.message = "An error occurred. Please try again.";
    snackbar.value.error.visible = true;
    console.error("submitScan error:", error);
  } finally {
    scanId.value = "";
    isLoading.value = false; // Stop the loader
  }
};

function onDetect(content) {
  try {
    const scannedCode = content[0]?.rawValue;
    if (scannedCode) {
      console.log("Scanned QR Code:", scannedCode);

      submitScan(scannedCode);
    } else {
      throw new Error("No valid QR code detected");
    }
  } catch (e) {
    error.value = "Invalid QR Code";
    logError(e);
  }
}

async function onCameraReady() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(({ kind }) => kind === "videoinput");

  constraintOptions.value = [
    ...defaultConstraintOptions,
    ...videoDevices.map(({ deviceId, label }) => ({
      label: `${label} (ID: ${deviceId})`,
      constraints: { deviceId },
    })),
  ];

  error.value = "";
}

function onError(err) {
  error.value = `[${err.name}]: `;

  if (err.name === "NotAllowedError") {
    error.value += "you need to grant camera access permission";
  } else if (err.name === "NotFoundError") {
    error.value += "no camera on this device";
  } else if (err.name === "NotSupportedError") {
    error.value += "secure context required (HTTPS, localhost)";
  } else if (err.name === "NotReadableError") {
    error.value += "is the camera already in use?";
  } else if (err.name === "OverconstrainedError") {
    error.value += "installed cameras are not suitable";
  } else if (err.name === "StreamApiNotSupportedError") {
    error.value += "Stream API is not supported in this browser";
  } else if (err.name === "InsecureContextError") {
    error.value +=
      "Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
  } else {
    error.value += err.message;
  }

  logError(error.value);
}
</script>

<style scoped>
.headerTitle {
  display: flex;
  justify-content: space-between;
  width: 96%;
  background-color: white;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
}
.background {
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 30px;
  align-items: center;
}
.full-page {
  width: 100vw;
  height: 100vh;
  display: flex;

  align-items: center;
  margin: 0;
}
</style>
