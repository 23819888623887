<template>
  <div class="friendlier-card-container">
    <slot />
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";
@import "@/revamp/assets/scss/variables.scss";
.friendlier-card-container {
  min-height: 300px;
  min-width: 350px;
  width: 0%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: $denim;
  border-radius: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}
</style>
