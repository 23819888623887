import { projectFirestore, timestamp } from "../firebase/config";
import { getDetails } from "../composables/getDetails";

//Function that pulls a list of errors from Firebase
export function getErrors() {
  let errorList = [];
  //let errorCount = 0;

  const pull = async () => {
    const docRef = await projectFirestore
      .collection("errors")
      .orderBy("timeRecorded")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          errorList.push(doc.data());
          //errorCount++;
        });
      })
      .catch((error) => {
        console.log("error");
      });
  };

  pull();

  return {
    errorList,
    //errorCount,
  };
}

//function that logs errors (adding an error to firebase)
export async function errorLog(errorMsg, boxId, contId, resId) {
  console.log("logging error");
  console.log(errorMsg, boxId, contId, resId);

  //this function requires an error msg
  if (!errorMsg) {
    errorMsg = "Error log failed. Please submit an error msg when you call errorLog()";
    contId = "";
    boxId = "";
    console.log(errorMsg);
    return;
  }

  const details = await getDetails();
  let docName = "";

  //the error document will be named based on what item (box or container) is throwing the error
  let urlReg = RegExp("(http|HTTP)");
  let dlReg = RegExp("(afriendliercompany|AFRIENDLIERCOMPANY)");

  if (contId) {
    if (contId.match(urlReg) != null) {
      // URL found, check for ours
      if (contId.match(dlReg) != null) {
        // DL found
        let split = contId.split("/");
        docName = `DL-${split[split.length - 1]} ${details.dateTime.format()}`;
      } else {
        // Sketchy Link
        docName = `UNKNOWN URL ${details.dateTime.format()}`;
      }
    } else {
      // Pure code, all fine
      docName = `${contId} ${details.dateTime.format()}`;
    }
  } else if (boxId) {
    docName = `${boxId} ${details.dateTime.format()}`;
  } else {
    docName = `Backend Error ${details.dateTime.format()}`;
  }

  //fill the empty parameter with a more readable "empty" value
  if (!boxId) boxId = "N/A";
  if (!resId) resId = "N/A";
  if (!contId) contId = "N/A";

  //log the error in the errors collection
  await projectFirestore.collection("errors").doc(docName).set({
    boxId: boxId,
    contId: contId,
    error: errorMsg,
    open: true,
    resId: resId,
    staffName: details.fullName,
    timeRecorded: details.dateTime.format(),
    timeClosed: null,
  });

  let bodyString = `*Error!*: ${errorMsg} \n *Found by*: ${details.fullName} \n *At*: ${details.dateTime.format()} \n *Details*: \n` + (contId != "N/A" ? `\t *Active Container*: ${contId}\n` : "") + (boxId != "N/A" ? `\t *Selected Box*: ${boxId}\n` : "") + (resId != "N/A" ? `\t *Selected Restaurant*: ${resId}\n` : "");

  await fetch(process.env.VUE_APP_ERROR_SLACK_BOT_URL, {
    method: "POST",
    body: JSON.stringify({
      type: "mrkdwn",
      text: bodyString,
    }),
  });

  //log the error in a container's error subcollection
  if (contId != "N/A" && contId.match(urlReg) == null)
    await projectFirestore.collection("QRCodes").doc(contId).collection("errors").doc(docName).set({
      boxId: boxId,
      contId: contId,
      error: errorMsg,
      open: true,
      resId: resId,
      staffName: details.fullName,
      timeRecorded: details.dateTime.format(),
      timeClosed: null,
    });
}
