export function emailValidator(email) {
  let pattern =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  let matches = email.match(pattern);

  if (matches == null) return false;
  return true;
}

export function emailMatch(email1, email2) {
  if (email1 == "" || email2 == "") {
    return "";
  }

  if (email1 != email2) {
    return "Emails do not match";
  }
  return "";
}