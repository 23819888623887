<template>
  <div class="has-navbar-fixed-top pb-5">
    <nav class="navbar is-fixed-top has-shadow">
      <div class="container">
        <!-- Logo -->
        <div class="navbar-brand">
          <a class="navbar-item mr-5" href="https://www.afriendliercompany.ca/" target="_blank" rel="noopener noreferrer">
            <img src="../assets/logos/friendlier_secondary-ocean.png" style="max-height: 30px" />
          </a>
          <button
            @click="makeBurger"
            class="button navbar-burger"
            data-target="navbarBasicExample"
            v-bind:class="{ 'is-active': activator }"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>

        <!-- Page Links -->
        <div class="navbar-menu" id="navbarBasicExample" v-bind:class="{ 'is-active': activator }">
          <div class="navbar-start m-3">
            <a class="is-size-5">
              <router-link to="/dashboard" class="navbar-item">
                {{ $t("displayVue.home") }}
              </router-link>
            </a>

            <a
              class="navbar-item is-size-5"
              href="https://www.friendlier.ca/locations"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t("displayVue.locations") }}
            </a>

            <a
              class="navbar-item is-size-5"
              href="https://www.afriendliercompany.ca/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t("displayVue.contact-us") }}
            </a>
          </div>

          <!-- My Account -->
          <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link is-size-5">
                {{ $i18n.locale }}
              </div>
              <div class="navbar-dropdown">
                <div
                  class="navbar-item has-text-info is-size-5 is-clickable"
                  @click="
                    () => {
                      $i18n.locale = 'en';
                    }
                  "
                >
                  English
                </div>
                <div
                  class="navbar-item has-text-info is-size-5 is-clickable"
                  @click="
                    () => {
                      $i18n.locale = 'fr';
                    }
                  "
                >
                  French
                </div>
              </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link is-size-5">
                {{ $t("displayVue.my-account") }}
              </div>

              <div class="navbar-dropdown">
                <a class="navbar-item">
                  <router-link to="/change_password" class="has-text-info is-size-5">{{
                    $t("displayVue.change-password")
                  }}</router-link>
                </a>
                <a class="navbar-item">
                  <router-link to="/change_email_request" class="has-text-info is-size-5">{{
                    $t("displayVue.change-email")
                  }}</router-link>
                </a>
              </div>
            </div>
            <div class="navbar-item">
              <button @click="handleLogout" class="button is-info">
                {{ $t("displayVue.log-out") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import { getUser, useLogout } from "@/composables";

const { user } = getUser();
const { logout } = useLogout();
const router = useRouter();

const activator = ref(false);

watch(user, () => {
  if (!user.value) {
    router.push("/");
  }
});

function makeBurger() {
  console.log("MakeBurger");
  activator.value = !activator.value;
}

//Function to logout
async function handleLogout() {
  await logout();
  if (!logoutError.value) {
    //push back to Login
  }
}
</script>

<style></style>
