import { datadogLogs } from "@datadog/browser-logs";

class DatadogLoggingService {
  constructor() {
    this.isInitialized = false;
    this.initialize();
  }

  initialize() {
    const requiredDatadogConfig = [process.env.VUE_APP_DATADOG_CLIENT_TOKEN, process.env.VUE_APP_DATADOG_SITE];
    const hasRequiredDatadogConfig = requiredDatadogConfig.every((value) => value !== undefined);

    if (!hasRequiredDatadogConfig) {
      console.error("Missing required Datadog configuration");
      return;
    }

    const commonInitOptions = {
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.VUE_APP_DATADOG_SITE,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    };

    if (!this.isInitialized) {
      datadogLogs.init({
        ...commonInitOptions,
      });
      this.isInitialized = true;
      console.log("Datadog initialized");
    }
  }
  logInfo(errorMessage, customAttributes = {}) {
    // add any filtering logic here
    let message = errorMessage;
    let attributes = customAttributes;
    datadogLogs.logger.info(message, attributes);
  }
  logError(errorMessage, customAttributes = {}) {
    // add any filtering logic here
    let message = errorMessage;
    let attributes = customAttributes;
    datadogLogs.logger.error(message, attributes);
  }
}

const datadogLoggingServiceInstance = new DatadogLoggingService();
export default datadogLoggingServiceInstance;
