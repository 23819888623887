export default {
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
  "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proceed"])},
  "loginVue": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "log-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account yet?"])},
    "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "err-enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an email and password"])},
    "err-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email or password"])}
  },
  "displayVue": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my account"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locations"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change password"])},
    "change-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change email"])},
    "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log out"])},
    "hey": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["hey ", _interpolate(_named("firstname")), ","])},
    "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome back!"])},
    "containers-reused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containers reused"])},
    "available-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available balance"])},
    "pending-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending balance"])},
    "your-containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your containers"])},
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for choosing to reuse! You're making a huge difference."])},
    "impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your impact alone is equivalent to:"])},
    "plastic-waste": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reused")), "g of plastic waste"])},
    "charge-phone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Energy to charge your phone ", _interpolate(_named("reused")), " times"])},
    "co2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reused")), "g of CO2 emission"])},
    "pending-return": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("totalUnconfirmed")), " containers pending return"])},
    "find-dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find a drop off location"])},
    "deposit-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposits"])},
    "local-charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Interac e-transfer of your deposits, or choose to donate them to a local charity."])},
    "err-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while trying to send your email. Please reach out to Friendlier for support or try again later."])},
    "success-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent ! Check your inbox, make sure to check your junk too :)"])}
  },
  "paymentRequest": {
    "request-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request payment"])},
    "confirm-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your e-transfer email below to request payment."])},
    "confirm-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Request"])},
    "change-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change E-Transfer Email"])},
    "enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your e-transfer email below"])},
    "etransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Transfer Email"])},
    "confirm-etransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm E-Transfer Email"])},
    "sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("currBalance")), " will be sent to ", _interpolate(_named("eTransferEmail")), ". Do you wish to proceed?"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to proceed"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-transfer email successfully updated!"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update E-Transfer email please try again, or contact Friendlier for support"])},
    "make-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure the new E-transfer email is different from your existing one"])},
    "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails do not match"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your email before requesting a deposit payment."])},
    "not-enough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have enough in your balance to request payment"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Request sent! The e-transfer will be delivered in 3-5 business days"])},
    "failed-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to request payment, try again later or contact Friendlier for support"])},
    "current-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current email"])}
  },
  "changePassword": {
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change password"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons, you'll have to check your email inbox to change password."])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to have password sent to your email. You'll be logged out after clicking"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox! We've sent you an email with a link to change your password."])}
  },
  "changeEmailRequest": {
    "change-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change email"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons, you'll have to check your email inbox to change email."])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to have email sent to your email. You'll be logged out after clicking"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox! We've sent you an email with a link to change your email."])}
  },
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Verify Email"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Verification Email"])},
  "claimContainer": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["claim your deposits!"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our mobile app to claim your deposit."])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on both android and ios"])},
    "already-claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container has already been claimed please place in a return bin"])},
    "oops-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong. Please try again"])},
    "verify-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your email before claiming a container."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container successfully claimed! Please place in a return bin."])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again later"])}
  },
  "donateRequest": {
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donate balance"])},
    "donate-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate your balance to one of our recommended charities"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "donate-to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donate my deposits to ", _interpolate(_named("name"))])},
    "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporarily Down"])},
    "service-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note, our dontation service is currently down - we are working to fix this quickly! Thank you."])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$ will be donated to this charity. Do you wish to proceed?"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your email before donating your deposit."])}
  },
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "registerForm": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
    "valid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
    "all-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete all fields"])},
    "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix problems before submitting"])}
  },
  "emailUpdateForm": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "new-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new email"])},
    "confirm-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm email"])}
  },
  "signup": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again in a few minutes or reach out to Friendlier for help."])}
  },
  "validator": {
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be longer than 8 characters"])},
    "password-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])}
  },
  "forgotPassword": {
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email to reset password:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an email"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this email has an account, check the inbox for a reset password link"])}
  },
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
}