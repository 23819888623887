import { projectFirestore, increment, increment1, decrement, decrement1, timestamp, adjustVal } from "../firebase/config";
import { errorLog } from "./errorHandling";
import { logError } from "@/revamp/utils";
import { FIREBASE_FUNCTION_HEADERS } from "@/revamp/firebase/config";

const CODE_COLLECTION = "QRCodes";
const PRODUCT_COLLECTION = "products";
const RESTAURANT_COLLECTION = "restaurants";
const TOTAL_COLLECTION = "totals";
const PARAM_COLLECTION = "parameters";
const USER_COLLECTION = "users";
const SCAN_COLLECTION = "scans";
const STORY_COLLECTION = "story";

/***
 * Desc: Return The firebase doc corresponding to the id - otherwise null
 * External Connections: Firebase Firestore
 * Params/Input: RestaurantID: Document ID in the restaurant collection
 * Return/Output: Firebase Document: Corresponding restaurant doc
 ***/
const getRestaurant = async (restaurantID) => {
  if (restaurantID == null || restaurantID == undefined || restaurantID == "") {
    return null;
  }

  // let restReg = RegExp("((R)[0-9]*)");
  // if (restaurantID.match(restReg) == null) {
  //   return null;
  // }

  let restaurant_doc = await projectFirestore.collection(RESTAURANT_COLLECTION).doc(restaurantID).get();

  if (!restaurant_doc.exists) return null;

  return restaurant_doc;
};

/***
 * Desc: Return the firebase doc corresponding to the id - otherwise null
 * External Connections: Firebase Firestore
 * Params/Input: codeID: Document ID in the QRCode collection
 * Return/Output: Firebase Document: Corresponding to the QRCode ID
 ***/
const getQRCode = async (codeID) => {
  if (codeID == null || codeID == undefined || codeID == "") {
    return null;
  }
  let qrCodeDoc = await projectFirestore.collection(CODE_COLLECTION).doc(codeID).get();

  if (!qrCodeDoc.exists) return null;

  return qrCodeDoc;
};

/***
 * Desc: Return the firebase doc corresponding to the id - otherwise null
 * External Connections: Firebase Firestore
 * Params/Input: uid: Document ID in the user collection
 * Return/Output: Firebase Document: Corresponding to the UserID
 ***/
const getUser = async (uid) => {
  if (uid == null || uid == undefined || uid == "") return null;

  let userDoc = await projectFirestore.collection(USER_COLLECTION).doc(uid).get();

  if (!userDoc.exists) return null;

  return userDoc;
};

const getProducts = async () => {
  let productCollection = await projectFirestore.collection(PRODUCT_COLLECTION).get();
  if (productCollection == null) return null;

  return productCollection.docs;
};

/***
 * Desc: Translate a incoming code from a Dynamic Link to a QRCode
 * External Connections: Firebase Firestore
 * Params/Input: A Code or Dynamic Link
 * Return/Output: Corresponding QR Code Document ID
 ***/
const translateDynamicLink = async (code) => {
  let codeReg = RegExp("((C|P|c|p)[a-zA-Z0-9]{6})");
  let urlReg = RegExp(`(https?:\/\/(www\.)?[Z-a]*)`);

  if (code == null || code == undefined || code == "") return null;

  if (code.match(urlReg) != null) {
    console.log("Found a url");
    // Check for a DL
    let url_query = await projectFirestore.collection(CODE_COLLECTION).where("url", "==", code).get();

    // Return the matching ID for the URL or error out
    if (url_query.docs.length > 0 && url_query.docs[0].exists) {
      return url_query.docs[0].id;
    } else {
      return null;
    }
  } else {
    console.log("Found a code");
    return code;
  }
};

/***
 * Desc: Increment a total counter by 1
 * External Connections: Firebase Firestore
 * Params/Input: doc: the totals document, field: the corresponding field in the doc to increment
 * Return/Output: N/A
 ***/
const incrementTotalByOne = async (doc, field) => {
  await projectFirestore
    .collection(TOTAL_COLLECTION)
    .doc(doc)
    .set(
      {
        [field]: increment,
      },
      { merge: true }
    );
};

/***
 * Desc: Increment a total counter by a half
 * External Connections: Firebase Firestore
 * Params/Input: doc: the totals document, field: the corresponding field in the doc to increment
 * Return/Output: N/A
 ***/
const incrementTotalByHalf = async (doc, field) => {
  await projectFirestore
    .collection(TOTAL_COLLECTION)
    .doc(doc)
    .set(
      {
        [field]: increment1,
      },
      { merge: true }
    );
};

/***
 * Desc: Increment a total counter by a given value
 * External Connections: Firebase Firestore
 * Params/Input: doc: the totals document, field: the corresponding field in the doc to increment
 * Return/Output: N/A
 ***/
const incrementTotalByValue = async (doc, field, val) => {
  try {
    if (val == NaN) throw new Error("VALUE NOT A NUMBER");
    try {
      let test = val;
      test += 1;
    } catch (err ) {
      throw new Error("VALUE NOT A NUMBER");
    }
    await projectFirestore
      .collection(TOTAL_COLLECTION)
      .doc(doc)
      .set(
        {
          [field]: adjustVal(val),
        },
        { merge: true }
      );
  } catch (error) {
    errorLog(`FB.incrementTotalByValue: Failed to increment ${doc}/${field}. ${error.toString()}`, "", "", "");
  }
};

const incrementStaffByOne = async (staff, field) => {
  await projectFirestore
    .collection(USER_COLLECTION)
    .doc(staff)
    .set(
      {
        [field]: increment,
      },
      { merge: true }
    );
};

const userReturnContainer = async (uid, deposit, addDeposit, inSmartBin) => {
  // let userDoc = await getUser(uid);
  try {
    let userData = {
      totalConfirmed: increment,
      totalUserCount: increment,
      totalUnconfirmed: decrement,
    };

    if (deposit != 0 && !deposit) deposit = 0.5;

    console.log(addDeposit,!inSmartBin);

    if (addDeposit && !inSmartBin) {
      userData["balance"] = adjustVal(deposit);
      userData["pendingBalance"] = adjustVal(-deposit);
    }

    console.log(userData);

    await projectFirestore.collection(USER_COLLECTION).doc(uid).set(userData, { merge: true });
  } catch (e) {
    errorLog(`FB.UserReturnContainer: Failed to return container. ${e.toString()}`, "", "", "");
  }
};

const addUserScan = async (uid, scanReference) => {
  // let userDoc = await getUser(uid);
  try {
    await projectFirestore.collection(USER_COLLECTION).doc(uid).collection(SCAN_COLLECTION).doc(scanReference).set(
      {
        isConfirmed: true,
        timeConfirmed: timestamp(),
      },
      { merge: true }
    );
  } catch (e) {
    errorLog(`FB.AddUserScan: Failed to add user scan. ${e.toString()}`, "", "", "");
  }
};

const returnContainer = async (codeID, returnedFrom, deposit) => {
  // let codeDoc = await getQRCode(codeID);

  try {
    if (deposit != 0 && !deposit) deposit = 0.5;

    await projectFirestore.collection(CODE_COLLECTION).doc(codeID).set(
      {
        isReturned: false,
        home: true,
        distributed: "At Facility",
        boxId: "",
        uses: increment,
        deposit: deposit,
        inSmartBin: false,
      },
      { merge: true }
    );
  } catch (e) {
    errorLog(`FB.ReturnContainer: Failed to return container, could not update data. ${e.toString()}`, "", codeID, "");
  }

  try {
    // Add a story
    await projectFirestore.collection(CODE_COLLECTION).doc(codeID).collection(STORY_COLLECTION).add({
      type: "returned",
      time: timestamp(),
      location: returnedFrom,
    });
  } catch (e) {
    errorLog(`FB.ReturnContainer: Failed to return container, could not add story. ${e.toString()}`, "", codeID, "");
  }
};

const incrementRestaurantTotalByOne = async (restaurantDoc, field, asId = false) => {
  try {
    if (asId == true) {
      restaurantDoc = getRestaurant(restaurantDoc);
    }
    if (restaurantDoc == null || !restaurantDoc.exists) throw new Error("Restaurant Not Found");

    await restaurantDoc.ref.set(
      {
        [field]: increment,
      },
      { merge: true }
    );
  } catch (e) {
    errorLog(`IncrementRestaurantTotalByOne: Failed to update ${field} of restaurant, ${e.toString()}`, "", "", "");
  }
};

const adjustRestaurantValue = async (restaurantID, field, value) => {
  try {
    if (restaurantID == "" || restaurantID == null || restaurantID == undefined) {
      throw new Error("No restaurant");
    }

    await projectFirestore
      .collection(RESTAURANT_COLLECTION)
      .doc(restaurantID)
      .set(
        {
          [field]: adjustVal(value),
        },
        { merge: true }
      );
  } catch (e) {
    errorLog(`FB.adjustRestaurantValue: Failed to adjust restaurant value. ${e.toString()}`, "", "", "");
  }
};

const getOutletGroupMembers = async (outletGroupName) => {
  try {
    let details = await projectFirestore
      .collection(PARAM_COLLECTION)
      .doc("analytics")
      .collection("outletGroups")
      .where("Name", "==", outletGroupName)
      .get();

    if (details.docs.length <= 0 || !details.docs[0].exists) {
      return [];
    }
    return details.docs[0].data()["outlets"];
  } catch (e) {
    errorLog(`FB.getOutletGroupMembers: Failed to fetch outlet group members. ${e.toString()}`, "", "", "");
    return [];
  }
};

const getStripePrices = async () => {
  try {

    let response = await fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/getStripePrices`, {
      method: "GET",
      headers: FIREBASE_FUNCTION_HEADERS,
    });

    let data = await response.json();

    let products = {}

    for (let price of data['result']) {
      if (!(price['product'] in products)) products[price['product']] = {}
      products[price['product']][price['id']] = price
      if (price['default']) {
        products[price['product']]['default'] = price
      }
    }
    return products

  } catch (e) {
    logError(`useFirebase.getStripePrices: Failed to getStripePrices. ${e.toString()}`)
  }
  return null;
}

const lookupDeposit = async (code) => {
  try {
    if (code == null || code == undefined) throw new Error('Null or Undefined code');

    if (!'distributed'in code['data']) throw new Error('Code not sent');

    let restaurant = await getRestaurant(code['data']['distributed'])
    if (restaurant == null || restaurant == undefined) throw new Error('Failed to get restaurant');

    let restaurantData = restaurant.data();
    if (restaurantData['depIncluded']) return 0;

    let stripePrices = await getStripePrices();
    if (stripePrices == null || stripePrices == undefined) throw new Error('Failed to get Stripe prices')

    let product = await projectFirestore.collection('products').where('boxIDTag','==',code['data']['type']).get()
    product = product.docs[0]

    let productData = product.data();
    if (restaurantData['priceGroup']) {
      let priceGroup = await projectFirestore.collection('parameters').doc('priceGroups').get()
      let priceGroupData = priceGroup.data();
      return stripePrices[productData['stripeProductIDDeposit']][priceGroupData[restaurantData['priceGroup']][productData['boxIDTag']]['deposit']]['amount'] / 100
    } else {
      return stripePrices[productData['stripeProductIDDeposit']]['default']['amount'] / 100
    }
  } catch (e) {
    logError(`useFirebase.lookupDeposit: Failed to lookup deposit ${code['ref'].id}. ${e.toString()}`)
  }
  return 0.5
}

const useFirebase = () => {
  return {
    getRestaurant,
    getQRCode,
    getProducts,
    getUser,

    translateDynamicLink,
    incrementTotalByOne,
    incrementTotalByHalf,
    incrementTotalByValue,
    incrementStaffByOne,
    addUserScan,
    userReturnContainer,
    returnContainer,
    incrementRestaurantTotalByOne,
    adjustRestaurantValue,
    getOutletGroupMembers,
    lookupDeposit,
  };
};

export default useFirebase;
