import getUser from "../composables/getUser";
import { projectFirestore, timestamp } from "../firebase/config";


export async function getDetails() {
  const { user } = getUser();
  const uniqueId = user.value.uid.toString();

  //access the databases
  const docRef = await projectFirestore.collection("users").doc(uniqueId).get();
  const data = docRef.data();

  let verified = user.value.emailVerified;
  let admin = data.admin ? data.admin : false;
  let staff = data.staff ? data.staff : false;
  let firstName = data.fname;
  let lastName = data.lname;
  let fullName = firstName + " " + lastName;
  let email = data.email;
  let containersScanned = data.containersScanned;
  let boxesPacked = data.boxesPacked;

  const totalsDoc = await projectFirestore.collection("totals").doc("deposits").get();
  let totalContainersResused = totalsDoc.data().totalReturned;

  return {
    verified,
    uniqueId,
    dateTime: new Date(),
    firstName,
    lastName,
    fullName,
    containersScanned,
    boxesPacked,
    totalContainersResused,
    staff,
    admin,
    email,
  };
}
