import { ref } from "vue"
import { AuthService } from "@/revamp/firebase/config"

const logoutError = ref(null)

const logout = async () => {
  logoutError.value = null
  try{
    await AuthService.signOut()
    logoutError.value = null
  }catch(err){
    logoutError.value = err.message
  }
}

const useLogout = () => {
  return {logoutError, logout}
}

export default useLogout