<template>
  <div class="content">
    <Background>
      <Login v-if="display == 0" :signup="() => setComponent(1)" :forgot="() => setComponent(2)" />
      <Signup v-else-if="display == 1" :login="() => setComponent(0)" />
      <ForgotPassword v-else-if="display == 2" :back="() => setComponent(0)" />
    </Background>
  </div>
</template>
<script>
import { ref } from "vue";
import { Login, Signup, ForgotPassword, Background } from "@/revamp/components";

export default {
  name: "Welcome",
  components: {
    Login,
    Signup,
    ForgotPassword,
    Background,
  },
  setup() {
    const display = ref(0);

    async function setComponent(page) {
      display.value = page;
    }

    return {
      // Methods
      setComponent,
      // Data
      display,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";
@import "@/revamp/assets/scss/variables.scss";
</style>
