export function passwordValidator(p1) {
  if (p1 == "") {
    return "";
  }

  if (p1.length < 8) {
    return "Password must be longer than 8 characters";
  }

  let pattern = /(?=.*[a-zA-Z]+)(?=.*[0-9]+)[A-Za-z\d!@#$%^&*()\-_=+\[\]\{\},<.>~?]{8,}/;
  let matches = p1.match(pattern);

  if (matches == null) {
    return "Password must include at least 1 uppercase, and 1 number";
  }

  return "";
}

export function passwordMatch(p1, p2) {
  if (p1.value == "" || p2 == "") {
    return "";
  }

  if (p1 != p2) {
    return "Passwords do not match";
  }

  return "";
}
