/***** QRCODES *****/
import { QRCode_Functions } from "@/revamp/composables/db";
/***** BOXES *****/
/***** CHARITIES *****/
/***** ERRORS *****/
import { Error_Functions } from "@/revamp/composables/db";
/***** INVENTROY *****/
/***** ORDERS *****/
/***** PARAMETERS *****/
/***** PAYMENTREQUESTS *****/
/***** PRODUCTS *****/
/***** RESTAURANTS *****/
import { Outlet_Functions } from "@/revamp/composables/db";
/***** TOTALS *****/
import { Total_Functions } from "@/revamp/composables/db";
/***** USERS *****/
import { User_Functions } from "@/revamp/composables/db";

import {
  timestamp,
} from "@/revamp/firebase/config";

const useFirebase = () => {
  return {
    ...QRCode_Functions,
    ...Error_Functions,
    ...Outlet_Functions,
    ...User_Functions,
    ...Total_Functions,
    timestamp,
  };
};

export default useFirebase;
