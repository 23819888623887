;
<template>
  <div class="is-flex is-align-items-center is-flex-direction-column p-6">
    <!-- BOX STARTS -->
    <div class="header-row is-justify-content-center mb-3">
      <p class="is-family-secondary is-size-4 is-denim">
        {{ $t("paymentRequest.request-payment") }}
      </p>
      <Loading v-if="loading == true" />
    </div>

    <!-- If email is already entered -->
    <template v-if="eTransferEmail != '' && !changing">
      <p class="has-text-weight-light mb-4 is-denim">
        {{ $t("paymentRequest.confirm-email") }}
      </p>
      <div class="column is-one-third has-text-centered">
        <p class="pt-1 pb-4 has-text-centered is-size-5 is-family-secondary">
          <span class="is-denim has-text-weight-light">{{ $t("paymentRequest.current-email") }}:</span>{{ "  " }}
          <span class="is-sunrise">{{ eTransferEmail }}</span>
        </p>
      </div>
      <span class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
        <button class="button is-primary is-size-6 mx-3 mb-1" style="min-width: 215px" @click="toggleConfirmModal">
          {{ $t("paymentRequest.confirm-request") }}
        </button>

        <!-- Change e-transfer email -->
        <button class="button is-light is-size-6 mx-3 mb-1" @click="toggleChanging" style="min-width: 215px">
          {{ $t("paymentRequest.change-email") }}
        </button>
      </span>
    </template>

    <!-- If email is yet to be entered -->
    <template v-else>
      <p class="has-text-weight-light mb-2 is-denim">
        {{ $t("paymentRequest.enter-email") }}
      </p>
      <hr class="is-back-sunflower with-space is-full-width" />
      <form @submit.prevent="handleETransferEmailChange">
        <input
          type="email"
          v-model="eTransferInput"
          :placeholder="$t('paymentRequest.etransfer')"
          id="input_field"
          class="pt-1 pb-1 mt-3 input is-size-6"
          required
        />
        <input
          type="email"
          v-model="confETransferInput"
          :placeholder="$t('paymentRequest.confirm-etransfer')"
          id="input_field"
          class="pt-1 pb-1 mt-3 input is-size-6"
          required
          onpaste="return false;"
          ondrop="return false;"
          autocomplete="off"
        />

        <span class="is-flex is-justify-content-space-around">
          <button class="button is-info m-3 is-size-6" type="submit" @click="toggleChanging">
            {{ $t("cancel") }}
          </button>
          <button class="button is-info m-3 is-size-6" type="submit" @click="showModal2 = true">
            {{ $t("confirm") }}
          </button>
        </span>
      </form>
    </template>
  </div>
  <!-- BOX ENDS -->

  <div class="modal" :class="{ 'is-active': showNoticeModal }">
    <div class="modal-background" @click="toggleNoticeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <button class="delete" aria-label="close" @click="toggleNoticeModal"></button>
        <h1 class="ml-2">{{ $t("notice") }}</h1>
      </header>
      <section class="modal-card-body">
        <div
          :class="{
            'has-text-danger-dark': eTransferEmailChangeMessage['type'] == 'err',
            'has-text-success-dark': eTransferEmailChangeMessage['type'] == 'msg',
          }"
        >
          {{ eTransferEmailChangeMessage["value"] }}
        </div>
      </section>
      <footer class="modal-card-foot buttons is-centered">
        <button class="button is-info mt-1" @click="toggleNoticeModal">ok</button>
      </footer>
    </div>
  </div>

  <div class="modal" v-bind:class="{ 'is-active': showConfirmModal }">
    <div class="modal-background" @click="toggleConfirmModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirmation</p>
        <button class="delete" aria-label="close" @click="toggleConfirmModal"></button>
      </header>
      <section class="modal-card-body">
        <span v-if="!submitSuccess">
          {{
            $t("paymentRequest.sent", {
              currBalance: currBalance,
              eTransferEmail: eTransferEmail,
            })
          }}</span
        >
        <!-- Error and Success Messages -->
        <div
          v-if="submitRequestMessage && submitRequestMessage.value != ''"
          :class="{
            'has-text-danger-dark': submitRequestMessage['type'] == 'err',
            'has-text-success-dark': submitRequestMessage['type'] == 'msg',
          }"
        >
          <hr class="storm-cloud" />
          {{ submitRequestMessage.value }}
          <hr class="storm-cloud" />
        </div>
      </section>
      <footer v-if="!submitSuccess" class="modal-card-foot buttons is-centered">
        <button :disabled="submitClicked" class="button is-info is-light ml-5 is-size-6" type="submit" @click="submitRequest">
          {{ $t("paymentRequest.proceed") }}
        </button>
        <button class="button is-danger mt-1" style="width: 200px" @click="toggleConfirmModal" :disabled="submitClicked">
          {{ $t("cancel") }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Loading } from "@/revamp/components";
import { ref, onMounted, watch, computed } from "vue";
import { projectFirestore, timestamp, adjustVal } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { logError } from "@/revamp/utils";

import { useI18n } from "vue-i18n";

export default {
  components: {
    Loading,
  },
  props: {
    update: Function,
    sendVerification: Function,
    verify: Function,
  },
  setup(props) {
    //new
    const loading = ref(false);
    const changing = ref(false);
    const eTransferEmail = ref("");
    const { user } = getUser();
    const uniqueId = user.value.uid.toString();
    const eTransferEmailChangeMessage = ref("");
    const submitRequestMessage = ref("");

    const eTransferInput = ref("");
    const confETransferInput = ref("");

    const showNoticeModal = ref(false);
    const currBalance = ref(0);

    const showConfirmModal = ref(false);
    const submitClicked = ref(false);
    const submitSuccess = ref(false);

    const { t } = useI18n({
      locale: "en",
      messages: {
        en: {
          send: "Send Verification Email",
          notice: "Notice",
          close: "Close",
          email: "Please Verify Email",
          updated: "E-transfer email successfully updated!",
          failed: "Failed to update E-Transfer email please try again, or contact Friendlier for support",
          makeSure: "Please make sure the new E-transfer email is different from your existing one",
          match: "Emails do not match",
          verify: "Please verify your email before requesting a deposit payment.",
          notEnough: "You don't have enough in your balance to request payment",
          request: "Payment Request sent! The e-transfer will be delivered in 3-5 business days",
          failedRequest: "Failed to request payment, try again later or contact Friendlier for support",
        },
        fr: {
          send: "Envoyer le courriel de vérification",
          notice: "Avis",
          close: "Fermer",
          email: "Veuillez vérifier votre courriel",
          updated: "Le courriel de virement bancaire a été mis à jour avec succès !",
          failed:
            "Échec de la mise à jour du courriel de virement bancaire ; veuillez réessayer ou contacter Friendlier pour obtenir de l'aide",
          makeSure: "Veuillez vous assurer que le nouveau courriel de virement bancaire est différent de votre courriel actuel",
          match: "Les courriels ne correspondent pas",
          verify: "Veuillez vérifier votre courriel avant de demander le paiement d'une consigne.",
          notEnough: "Vous n'avez pas assez d'argent dans votre solde pour demander un paiement",
          request: "Demande de paiement envoyée ! Le virement bancaire sera effectué dans les 3 à 5 jours ouvrables",
          failedRequest: "La demande de paiement a échoué, réessayez plus tard ou contactez Friendlier pour obtenir de l'aide",
        },
      },
    });
    const msg = computed(() =>
      t(
        "notice",
        "send",
        "close",
        "email",
        "updated",
        "failed",
        "makeSure",
        "match",
        "verify",
        "notEnough",
        "request",
        "failedRequest"
      )
    );

    const getUserValue = async () => {
      const docRef = projectFirestore.collection("users").doc(uniqueId);
      await docRef.get().then((doc) => {
        let data = doc.data();
        eTransferEmail.value = "etransfer email" in data ? data["etransfer email"] : "";
        currBalance.value = "balance" in data ? data["balance"] : 0;
      });
    };

    onMounted(async () => {
      getUserValue();
    });

    watch(eTransferEmailChangeMessage, () => {
      getUserValue();
    });

    const toggleNoticeModal = () => {
      showNoticeModal.value = !showNoticeModal.value;
    };
    const toggleChanging = () => {
      eTransferInput.value = "";
      confETransferInput.value = "";
      changing.value = !changing.value;
    };
    const toggleConfirmModal = () => {
      showConfirmModal.value = !showConfirmModal.value;
    };

    const handleETransferEmailChange = async () => {
      eTransferEmailChangeMessage.value = "";
      loading.value = true;

      if (eTransferInput.value == confETransferInput.value) {
        if (eTransferInput.value != eTransferEmail.value) {
          try {
            await projectFirestore.collection("users").doc(uniqueId).set(
              {
                "etransfer email": eTransferInput.value.toLowerCase().trim(),
              },
              { merge: true }
            );
            eTransferEmailChangeMessage.value = {
              value: t("proceed"),
              type: "msg",
            };
            eTransferInput.value = "";
            confETransferInput.value = "";
            toggleNoticeModal();
          } catch (err) {
            eTransferEmailChangeMessage.value = {
              value: t("failed"),
              type: "err",
            };
            toggleNoticeModal();
            error1.value = err.message;
          }
        } else {
          eTransferEmailChangeMessage.value = {
            value: t("makeSure"),
            type: "err",
          };
          toggleNoticeModal();
        }
      } else {
        eTransferEmailChangeMessage.value = {
          value: t("match"),
          type: "err",
        };
        toggleNoticeModal();
      }
      loading.value = false;
      changing.value = false;
    };

    //Function that handles payment Request
    const submitRequest = async () => {
      submitRequestMessage.value = {
        value: "",
        type: "msg",
      };
      submitClicked.value = true;
      loading.value = true;
      submitSuccess.value = false;

      if (!user.value.emailVerified) {
        loading.value = false;
        toggleConfirmModal();
        submitRequestMessage.value = {
          type: "err",
          value: t("email"),
        };
        props.verify({
          title: t("notice"),
          body: t("verify"),
          buttons: [
            {
              text: t("close"),
              action: () => {
                props.verify({});
              },
            },
            {
              text: t("send"),
              action: () => {
                props.verify({});
                props.sendVerification();
              },
            },
          ],
        });
        return;
      }

      try {
        if (currBalance.value <= 0) {
          submitRequestMessage.value = {
            value: t("notEnough"),
            type: "err",
          };
          submitClicked.value = false;
          loading.value = false;
          return;
        }

        //update total
        await projectFirestore
          .collection("totals")
          .doc("transactions")
          .set({ totalClaimed: adjustVal(currBalance.value) }, { merge: true });

        // create document in user
        let requestID = await projectFirestore.collection("users").doc(uniqueId).collection("transactions").add({
          timeRequested: timestamp(),
          amount: currBalance.value,
          active: null,
          status: "requested",
        });

        await projectFirestore
          .collection("users")
          .doc(uniqueId)
          .update({
            totalConfirmed: 0,
            cashed: adjustVal(currBalance.value),
            balance: 0,
          });

        // create document in payments
        await projectFirestore.collection("paymentRequests").add({
          amount: currBalance.value,
          docRef: requestID.id,
          "etransfer email": eTransferEmail.value,
          status: "requested",
          timeRequested: timestamp(),
          uid: uniqueId,
        });

        submitRequestMessage.value = {
          value: t("request"),
          type: "msg",
        };
        submitClicked.value = false;
        submitSuccess.value = true;
        props.update();
        await getUserValue();
      } catch (e) {
        logError(`Failed to request payment: ${e.toString()}`);
        submitRequestMessage.value = {
          value: t("failedRequest"),
          type: "err",
        };
        submitClicked.value = false;
      }
      loading.value = false;
    };

    return {
      // Data
      loading,
      changing,
      eTransferEmail,
      eTransferEmailChangeMessage,
      submitRequestMessage,
      eTransferInput,
      confETransferInput,
      showNoticeModal,

      currBalance,
      showConfirmModal,
      submitClicked,
      submitSuccess,

      // Methods
      handleETransferEmailChange,
      toggleNoticeModal,
      toggleChanging,
      toggleConfirmModal,
      submitRequest,

      t,
      msg,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/elements.scss";

#input_field {
  background-color: #e1e1e1;
  border: none;
}

.storm-cloud {
  background-color: #717171;
  width: 90%;
  border-radius: 50px;

  &.with-space {
    margin: 25px auto;
  }
}

.header-row {
  width: 100%;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
