<template>
  <Modal />
  <router-view />
</template>

<script>
import { Modal } from "@/revamp/components";
export default {
  components: {
    Modal,
  },
  setup() {},
};
</script>
