<template>
  <div
    class="modal-background"
    :class="{
      active: isActive,
      cover: isCover,
    }"
    @click="close"
  >
    <div v-if="details" class="card modal-container">
      <div class="title">{{ details.title }}</div>
      <div class="body">{{ details.body }}</div>
      <div class="options-row">
        <div
          class="button option"
          v-for="option in details.options"
          :key="option.id"
          :onclick="
            () => {
              if (option.action != undefined) modalOptions[option.action]();
              if (option.callback != undefined) option.callback();
            }
          "
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { notifyStore } from "@/revamp/store";

export default {
  name: "Modal",
  setup() {
    const isActive = ref(false);
    const details = ref(null);
    const isCover = ref(false);

    watch(
      () => [...notifyStore.getState().messageQueue],
      () => {
        let message = notifyStore.readMessage();
        if (!message) return;
        if (message.target !== "modal") return;

        notifyStore.deQueue();
        isActive.value = true;
        details.value = message;
        isCover.value = details.value["isCover"];
      }
    );

    function dismiss() {
      isActive.value = false;
      details.value = null;
    }

    function tings(option) {}

    const modalOptions = { close: dismiss };

    return {
      // Data
      isActive,
      details,
      isCover,
      // Methods
      dismiss,
      modalOptions,
      tings,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/revamp/assets/scss/colors.scss";
.modal-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.active {
  display: flex;
}

.cover {
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
  background-color: $cloud;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 40px;
  height: fit-content;
  min-width: 30%;
  max-width: 90%;

  .title {
    color: $denim;
  }

  .body {
    margin-bottom: 15px;
  }

  .options-row {
    display: flex;
    justify-content: space-around;

    .option {
      background-color: $sunflower;
      color: $denim;
      border-radius: 10px;
    }
  }
}
</style>
