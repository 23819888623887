export default async function getQueryParams(clearUrl, path) {
  let url = window.location.href;
  let params = url.split("?");

  if (clearUrl) window.history.replaceState({}, document.title, path);

  let base = params[0];
  if (params.length == 1) {
    return {};
  }
  params = params[1].split("&");

  let data = {};
  for (let param of params) {
    let [key, val] = param.split("=");
    data[key] = val;
  }

  return data;
}
